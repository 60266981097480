import React, { useState, useContext, useEffect, useRef } from 'react'
import { AppContext } from 'components/App'
import Carousel from 'components/Carousel'
import InteractiveImage from 'components/InteractiveImage'
import Title from 'components/Title'
import { isMobile } from 'theme'

import { css } from 'aphrodite'
import styles from './styles'
import translate from 'core/translate'

const images = {
    desktop: {
        ru: [
            'assets/images/Solutions/videowall.png',
            'assets/images/Solutions/CCTV.png',
            'assets/images/Solutions/touchPanels.png',
            'assets/images/Solutions/checkoutScreens.png',
            'assets/images/Solutions/music.png',
            'assets/images/Solutions/displayDisplays.png'
        ],
        en: [
            'assets/images/Solutions/videowall_en.png',
            'assets/images/Solutions/CCTV_en.png',
            'assets/images/Solutions/touchPanels_en.png',
            'assets/images/Solutions/checkoutScreens_en.png',
            'assets/images/Solutions/music_en.png',
            'assets/images/Solutions/displayDisplays_en.png'
        ]
    },
    mobile: {
        ru: [
            'assets/images/Solutions/videowall_mob.png',
            'assets/images/Solutions/CCTV_mob.png',
            'assets/images/Solutions/touchPanels_mob.png',
            'assets/images/Solutions/checkoutScreens_mob.png',
            'assets/images/Solutions/music_mob.png',
            'assets/images/Solutions/displayDisplays_mob.png'
        ],
        en: [
            'assets/images/Solutions/videowall_en_mob.png',
            'assets/images/Solutions/CCTV_en_mob.png',
            'assets/images/Solutions/touchPanels_en_mob.png',
            'assets/images/Solutions/checkoutScreens_en_mob.png',
            'assets/images/Solutions/music_en_mob.png',
            'assets/images/Solutions/displayDisplays_en_mob.png'
        ]
    }
}

const IMAGE_PATH = 'assets/images/Solutions'

const defaultActiveDisplays = {
    slide_0: true,
    slide_1: false,
    slide_2: false,
    slide_3: false,
    slide_4: false,
    slide_5: false
}

const resetActiveDisplays = {
    ...defaultActiveDisplays,
    slide_0: false
}

const Solutions = () => {
    const [activeDisplays, setActiveDisplays] = useState({ ...defaultActiveDisplays })
    const [deviceType, setDeviceType] = useState(isMobile() ? 'mobile' : 'desktop')
    const appContext = useContext(AppContext)
    const locale = appContext.locale
    const oldWidth = window.innerWidth

    const handleResize = (e) => {
        let newWidth = window.innerWidth

        if (newWidth !== oldWidth) {
            setDeviceType(isMobile() ? 'mobile' : 'desktop')
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const getCarouselImages = () => {
        return images[deviceType][locale] ? images[deviceType][locale] : images[deviceType]['en']
    }

    const carouselRef = useRef(null)

    useEffect(() => {
        if (appContext.selectedSlide) {
            const activeDisplays = {
                ...resetActiveDisplays,
                [appContext.selectedSlide]: true
            }

            setActiveDisplays(activeDisplays)

            const slideIdx = appContext.selectedSlide.replace('slide_', '')
            carouselRef.current.slickGoTo(slideIdx)
        }
    }, [appContext.selectedSlide])

    const onClick = (selectedSlide) => {
        appContext.onSetSelectedSlide(selectedSlide)
    }

    return (
        <div className={css(styles.solutionsWrapper)} id="aboutUs">
            <Title textKey="solutions" />
            <div className={css(styles.solutionsInteractiveImageWrapper)}>
                <InteractiveImage onClick={onClick} active={activeDisplays} />
            </div>
            <div className={css(styles.solutionsCarouselContent)}>
                <Carousel
                    isRemovePaddingInMobile={true}
                    settings={{
                        ref: carouselRef,
                        beforeChange: (_, idx) => {
                            const activeDisplays = {
                                ...resetActiveDisplays,
                                [`slide_${idx}`]: true
                            }
                            setActiveDisplays(activeDisplays)
                        }
                    }}
                >
                    <div className={css(styles.solutionsCarouselSlide)}>
                        <div className={css(styles.solutionsCarouselTitle, styles.solutionsCarouselTitlePosition1)}>
                            {translate('solutions.slide1.title')}
                        </div>
                        <div className={css(styles.solutionsCarouselInfo)}>
                            <div className={css(styles.solutionsCarouselList, styles.solutionsCarouselListPosition1)}>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide1.text1')}
                                </div>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide1.text2')}
                                </div>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide1.text3')}
                                </div>
                            </div>
                            <img
                                className={css(styles.solutionsCarouselImage, styles.solutionsCarouselImagePosition1)}
                                srcSet={`
                                    ${IMAGE_PATH}/slideImage1@2x.png 2x,
                                    ${IMAGE_PATH}/slideImage1.png 1x,
                                `}
                                src={`${IMAGE_PATH}/slideImage1.png`}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className={css(styles.solutionsCarouselSlide)}>
                        <div className={css(styles.solutionsCarouselTitle, styles.solutionsCarouselTitlePosition2)}>
                            {translate('solutions.slide2.title')}
                        </div>
                        <div className={css(styles.solutionsCarouselInfo)}>
                            <div className={css(styles.solutionsCarouselList, styles.solutionsCarouselListPosition2)}>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide2.text1')}
                                </div>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide2.text2')}
                                </div>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide2.text3')}
                                </div>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide2.text4')}
                                </div>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide2.text5')}
                                </div>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide2.text6')}
                                </div>
                            </div>
                            <img
                                className={css(styles.solutionsCarouselImage, styles.solutionsCarouselImagePosition2)}
                                srcSet={`
                                    ${IMAGE_PATH}/slideImage2@2x.png 2x,
                                    ${IMAGE_PATH}/slideImage2.png 1x,
                                `}
                                src={`${IMAGE_PATH}/slideImage2.png`}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className={css(styles.solutionsCarouselSlide)}>
                        <div className={css(styles.solutionsCarouselTitle, styles.solutionsCarouselTitlePosition3)}>
                            {translate('solutions.slide3.title')}
                        </div>
                        <div className={css(styles.solutionsCarouselInfo)}>
                            <div className={css(styles.solutionsCarouselList, styles.solutionsCarouselListPosition3)}>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide3.text1')}
                                </div>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide3.text2')}
                                </div>
                            </div>
                            <img
                                className={css(styles.solutionsCarouselImage, styles.solutionsCarouselImagePosition3)}
                                srcSet={`
                                    ${IMAGE_PATH}/slideImage3@2x.png 2x,
                                    ${IMAGE_PATH}/slideImage3.png 1x,
                                `}
                                src={`${IMAGE_PATH}/slideImage3.png`}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className={css(styles.solutionsCarouselSlide)}>
                        <div className={css(styles.solutionsCarouselTitle, styles.solutionsCarouselTitlePosition4)}>
                            {translate('solutions.slide4.title')}
                        </div>
                        <div className={css(styles.solutionsCarouselInfo)}>
                            <div className={css(styles.solutionsCarouselList, styles.solutionsCarouselListPosition4)}>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide4.text1')}
                                </div>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide4.text2')}
                                </div>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide4.text3')}
                                </div>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide4.text4')}
                                </div>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide4.text5')}
                                </div>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide4.text6')}
                                </div>
                            </div>
                            <img
                                className={css(styles.solutionsCarouselImage, styles.solutionsCarouselImagePosition4)}
                                srcSet={`
                                    ${IMAGE_PATH}/slideImage4@2x.png 2x,
                                    ${IMAGE_PATH}/slideImage4.png 1x,
                                `}
                                src={`${IMAGE_PATH}/slideImage4.png`}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className={css(styles.solutionsCarouselSlide)}>
                        <div className={css(styles.solutionsCarouselTitle, styles.solutionsCarouselTitlePosition5)}>
                            {translate('solutions.slide5.title')}
                        </div>
                        <div className={css(styles.solutionsCarouselInfo)}>
                            <div className={css(styles.solutionsCarouselList, styles.solutionsCarouselListPosition5)}>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide5.text1')}
                                </div>
                            </div>
                            <img
                                className={css(styles.solutionsCarouselImage, styles.solutionsCarouselImagePosition5)}
                                srcSet={`
                                    ${IMAGE_PATH}/slideImage5@2x.png 2x,
                                    ${IMAGE_PATH}/slideImage5.png 1x,
                                `}
                                src={`${IMAGE_PATH}/slideImage5.png`}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className={css(styles.solutionsCarouselSlide)}>
                        <div className={css(styles.solutionsCarouselTitle, styles.solutionsCarouselTitlePosition6)}>
                            {translate('solutions.slide6.title')}
                        </div>
                        <div className={css(styles.solutionsCarouselInfo)}>
                            <div className={css(styles.solutionsCarouselList, styles.solutionsCarouselListPosition6)}>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide6.text1')}
                                </div>
                                <div className={css(styles.solutionsCarouselText)}>
                                    <div className={css(styles.solutionsCarouselIcon)}></div>
                                    {translate('solutions.slide6.text2')}
                                </div>
                            </div>
                            <img
                                className={css(styles.solutionsCarouselImage, styles.solutionsCarouselImagePosition6)}
                                srcSet={`
                                    ${IMAGE_PATH}/slideImage6@2x.png 2x,
                                    ${IMAGE_PATH}/slideImage6.png 1x,
                                `}
                                src={`${IMAGE_PATH}/slideImage6.png`}
                                alt=""
                            />
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
    )
}

export default Solutions
