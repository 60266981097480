import { StyleSheet } from 'aphrodite'
import { mixins, mobileBreakpoint } from 'theme'

const styles = StyleSheet.create({
    whySmartplayerWrapper: {
        padding: '5%',
        [mobileBreakpoint]: {
            padding: 20
        }
    },
    whySmartplayerContainer: {
        ...mixins.grid.fullWidth,
        ...mixins.grid.rowCenter,
        marginTop: '5%'
    },
    whySmartplayerContent: {
        ...mixins.grid.w70,
        [mobileBreakpoint]: {
            ...mixins.grid.w100,
            paddingBottom: 40
        }
    }
})

export default styles
