import React, { useContext } from 'react'
import Menu from 'components/Menu'
import Socials from 'components/Socials'
import { Link, withRouter } from 'react-router'

import translate from 'core/translate'
import { AppContext } from 'components/App'

import { css } from 'aphrodite'
import styles from './styles.js'

const Footer = ({ router }) => {
    const appContext = useContext(AppContext)
    const isMainPage = router.location.pathname === '/'

    const getCopyrights = () => {
        const date = new Date()
        const currentYear = date.getFullYear()

        return (
            typeof currentYear === 'number' && (
                <div className={css(styles.footerCopyrights)}>
                    © {window.spconfig.companyName}, {window.spconfig.yearOfFoundation}-{currentYear} {getAppVersion()}
                </div>
            )
        )
    }

    const onSelectMenu = (event) => {
        const selectedSlide = event.target.dataset.slide

        appContext.onSetSelectedSlide(selectedSlide)
    }
    const menuItems = [
        translate('videowall'),
        translate('CCTV'),
        translate('touchPanels'),
        translate('checkoutScreens'),
        translate('music'),
        translate('displayDisplays')
    ]

    const menu = menuItems.map((item, idx) => ({
        label: item,
        anchor: `slide_${idx}`,
        href: isMainPage ? '#aboutUs' : '/',
        anchorId: isMainPage ? 'aboutUs' : false,
        onClick: onSelectMenu,
        active: false
    }))

    const getAppVersion = () => {
        return process.env.REACT_APP_VERSION ? `(v${process.env.REACT_APP_VERSION})` : null
    }

    return (
        <div className={css(styles.footerWrapper)}>
            <div className={css(styles.footerContent)}>
                <Link className={css(styles.footerContentImageWrapper)} to="/" alt="">
                    <img
                        className={css(styles.footerContentImage, styles.footerContentImageBig)}
                        src="assets/images/Present/logo_big.svg"
                        alt=""
                    />
                    <img
                        className={css(styles.footerContentImage, styles.footerContentImageSmall)}
                        src="assets/images/Header/logo.svg"
                        alt=""
                    />
                </Link>
                <div className={css(styles.footerContentMenusWrapper)}>
                    <Menu type="vertical" id="displays" menu={menu} />
                    <Menu type="vertical" id="main" />
                    <Socials position="footer" />
                    <Menu type="vertical" id="modules" />
                </div>
                <div className={css(styles.sk)}>
                    <a href={window.spconfig.footerLogoUrl} target={'_blank'} rel="noopener noreferrer">
                        <img className={styles.sk_img} src={window.spconfig.footerLogoSrc} width={60} />
                    </a>
                </div>

                {getCopyrights()}
            </div>
        </div>
    )
}

export default withRouter(Footer)
