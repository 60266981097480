import { StyleSheet } from 'aphrodite'
import { mixins, mobileBreakpoint } from 'theme'

import { headerHeight } from 'components/Header/styles.js'

const styles = StyleSheet.create({
    wrapper: {
        ...mixins.grid.fullWidth,
        ...mixins.item.rel,
        ...mixins.item.hidden,
        height: `calc(100vh - ${headerHeight}px)`,
        [mobileBreakpoint]: {
            height: 'auto'
        }
    },
    contentWrapper: {
        ...mixins.grid.row,
        ...mixins.grid.full,
        ...mixins.item.rel,
        zIndex: 2
    },
    content: {
        ...mixins.grid.col,
        ...mixins.grid.justify,
        // Затемнение поверх видео
        background:
            'linear-gradient(180deg, rgba(38, 38, 38, 0) 50%, #171717 87.18%), linear-gradient(180deg, #262626 0%, rgba(53, 56, 144, 0.04) 100%)',
        top: 0,
        left: 0
    },
    presentTitleWrapper: {
        ...mixins.grid.rowCenter,
        [mobileBreakpoint]: {
            padding: 20
        }
    },
    presentTitleLogoWrapper: {
        ...mixins.grid.fullWidth,
        ...mixins.grid.rowCenter,
        marginBottom: 50
    },
    presentTitleLogo: {
        ...mixins.grid.w40,
        height: 'auto',
        maxWidth: 580,
        [mobileBreakpoint]: {
            ...mixins.grid.w60
        }
    },
    presentTitle: {
        ...mixins.grid.w50,
        ...mixins.text.center,
        ...mixins.text.s24,
        letterSpacing: '0.1em',
        lineHeight: '30px',
        fontWeight: 'normal',
        [mobileBreakpoint]: {
            ...mixins.grid.w100,
            ...mixins.text.s14,
            lineHeight: '19px'
        }
    },
    presentAdditionalWrapper: {
        ...mixins.grid.fullWidth,
        ...mixins.grid.rowCenter,
        ...mixins.grid.normalCenter,
        marginBottom: 10,
        [mobileBreakpoint]: {
            ...mixins.grid.col,
            ...mixins.grid.colReverse
        }
    },
    presentAdditionalImageWrapper: {
        ...mixins.grid.w25,
        height: 'auto',
        marginLeft: 60,
        [mobileBreakpoint]: {
            ...mixins.grid.rowCenter,
            ...mixins.grid.w100,
            marginLeft: 0,
            marginBottom: 10
        }
    },
    presentAdditionalImage: {
        ...mixins.grid.w100,
        [mobileBreakpoint]: {
            ...mixins.grid.w50
        }
    },
    decoration: {
        ...mixins.item.abs,
        bottom: 35,
        left: 35,
        [mobileBreakpoint]: {
            bottom: 10,
            left: 10
        }
    },
    presentVideoBackground: {
        ...mixins.item.abs,
        ...mixins.grid.wAuto,
        top: '50%',
        left: '50%',
        height: 'auto',
        zIndex: 0,
        minWidth: '100%',
        minHeight: '100%',
        transform: 'translateX(-50%) translateY(-50%)'
    },
    presentAdditionalButton: {
        ...mixins.grid.row,
        ...mixins.grid.normalCenter,
        ...mixins.text.up,
        ...mixins.text.s18,
        lineHeight: '25px',
        letterSpacing: '0.2em',
        padding: '24px 48px',
        boxShadow: '0px 5px 15px rgba(112, 0, 255, 0.5)',
        borderRadius: 50,
        [mobileBreakpoint]: {
            ...mixins.text.s12,
            ...mixins.text.up,
            ...mixins.text.center,
            lineHeight: '14px',
            padding: '14px 20px'
        }
    },
    presentAdditionalButtonVideo: {
        ...mixins.grid.row,
        ...mixins.grid.noWrap,
        ...mixins.grid.normalCenter,
        ...mixins.text.center,
        border: '3px solid #FFFFFF',
        background: 'transparent',
        boxShadow: 'none',
        padding: 4,
        ':hover': {
            background: 'transparent',
            opacity: 0.8
        },
        [mobileBreakpoint]: {
            padding: '10px 15px'
        }
    },
    presentAdditionalButtonMargin: {
        marginTop: 35
    },
    presentModalWrapper: {
        ...mixins.grid.row,
        ...mixins.grid.w60,
        ...mixins.item.rel,
        overflow: 'auto',
        padding: 20,
        borderRadius: 10,
        boxSizing: 'border-box',
        background: 'rgba(25, 25, 25, 0.9)',
        [mobileBreakpoint]: {
            ...mixins.grid.w100,
            ...mixins.grid.fullHeight
        }
    },
    modalContent: {
        ...mixins.grid.row,
        ...mixins.grid.w100,
        [mobileBreakpoint]: {
            ...mixins.grid.col,
            ...mixins.grid.colReverse,
            ...mixins.grid.flexStart
        }
    },
    presentModalCloseIcon: {
        ...mixins.item.abs,
        ...mixins.item.pointer,
        top: 10,
        right: 10
    },
    presentModalContentWrapper: {
        ...mixins.grid.w50,
        [mobileBreakpoint]: {
            ...mixins.grid.w100
        }
    },
    presentModalContent: {
        padding: 40,
        [mobileBreakpoint]: {
            padding: 5
        }
    },
    presentModalFormInput: {
        ...mixins.grid.w100,
        ...mixins.text.s14,
        ...mixins.text.white,
        ...mixins.text.openSans,
        boxSizing: 'border-box',
        lineHeight: '19px',
        background: `rgba(0,0,0, 0.5)`,
        borderRadius: 5,
        outline: 'none',
        border: 'none',
        padding: 10,
        marginBottom: 18,
        '::placeholder': {
            ...mixins.text.grey
        },
        ':last-child': {
            marginBottom: 0
        }
    },
    presentModalFormInputError: {
        background: `rgba(222,111,111, 0.6)`,
        '::placeholder': {
            ...mixins.text.white
        }
    },
    presentModalFormFooterButton: {
        ...mixins.text.center,
        ...mixins.text.bold,
        ...mixins.text.up,
        ...mixins.text.s18,
        lineHeight: '29px',
        display: 'block',
        letterSpacing: '0.2em',
        padding: '28px 0',
        borderRadius: 50,
        marginTop: 40
    },
    presentModalFormFooterButtonDisabled: {
        pointerEvents: 'none',
        cursor: 'not-allowed',
        opacity: 0.4
    },
    presentModalCheckbox: {
        marginTop: 10
    },
    presentModalDescription: {
        ...mixins.text.montserrat,
        ...mixins.text.bold,
        ...mixins.text.s36,
        lineHeight: '44px',
        letterSpacing: '0.1em'
    },
    presentModalContentAdditionalDescription: {
        marginTop: 20
    },
    presentModalContentList: {
        paddingTop: 15
    },
    presentModalContentLi: {
        ...mixins.grid.row,
        ...mixins.grid.normalCenter,
        ...mixins.grid.noWrap,
        ...mixins.grid.w100,
        paddingBottom: 10
    },
    presentModalContentLiText: {
        marginLeft: 10
    },
    presentAdditionalButtons: {
        ...mixins.grid.col
    },
    videoModalWrapper: {
        ...mixins.grid.full,
        background: 'black',
        padding: '40px 0'
    },
    presentAdditionalButtonText: {
        ...mixins.grid.space,
        ...mixins.grid.rowCenter
    },
    presentVideoModalCloseIcon: {
        right: 45
    }
})

export default styles
