import React from 'react'
import Icon from 'components/Icon'

import translate from 'core/translate'

import { css } from 'aphrodite'
import styles from './styles'

const WhatASmartplayer = () => (
    <div className={css(styles.whatWrapper)}>
        <div className={css(styles.whatTitle)}>
            <h2 className={css(styles.whatTitleContent)}>{translate('whatASmartplayer.title')}</h2>
        </div>
        <div className={css(styles.whatContent, styles.whatContentImageWrapper)}>
            <img className={css(styles.whatContentImage)} src="assets/images/WhatIsASmartplayer/img.png" alt="" />
        </div>
        <div className={css(styles.whatContent, styles.whatContentDescriptionWrapper)}>
            <div className={css(styles.whatContentDescription)}>
                <p dangerouslySetInnerHTML={{ __html: translate('whatASmartplayer.p1') }} />
                <br />
                <p>{translate('whatASmartplayer.p2')}</p>
                <br />
                <p>{translate('whatASmartplayer.p3')}</p>
                <br /> <br />
                <p>
                    <b>{translate('whatASmartplayer.descriptionTitle')}:</b>
                </p>
                <div className={css(styles.whatListWrapper)}>
                    <div className={css(styles.whatListItem)}>
                        <Icon type="li" size={13} color="blue" />
                        <div className={css(styles.whatListItemTitle)}>{translate('whatASmartplayer.listItem1')}</div>
                    </div>
                    <div className={css(styles.whatListItem)}>
                        <Icon type="li" size={13} color="blue" />
                        <div className={css(styles.whatListItemTitle)}>{translate('whatASmartplayer.listItem2')}</div>
                    </div>
                    <div className={css(styles.whatListItem)}>
                        <Icon type="li" size={13} color="blue" />
                        <div className={css(styles.whatListItemTitle)}>{translate('whatASmartplayer.listItem3')}</div>
                    </div>
                    <div className={css(styles.whatListItem)}>
                        <Icon type="li" size={13} color="blue" />
                        <div className={css(styles.whatListItemTitle)}>{translate('whatASmartplayer.listItem4')}</div>
                    </div>
                    <div className={css(styles.whatListItem)}>
                        <Icon type="li" size={13} color="blue" />
                        <div className={css(styles.whatListItemTitle)}>{translate('whatASmartplayer.listItem5')}</div>
                    </div>
                </div>
                <br />
                <p>
                    <b dangerouslySetInnerHTML={{ __html: translate('whatASmartplayer.bigDescription') }} />
                </p>
                <br />
                <br />
            </div>
        </div>
    </div>
)

export default WhatASmartplayer
