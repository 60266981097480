import { StyleSheet } from 'aphrodite'
import { mixins } from 'theme'

export const headerHeight = 68

const headerMobileBreakpoint = '@media(max-width: 1160px)'

const styles = StyleSheet.create({
    header: {
        ...mixins.grid.fullWidth,
        ...mixins.grid.row,
        ...mixins.grid.normalCenter,
        ...mixins.item.fixed,
        ...mixins.item.black,
        zIndex: 5,
        height: headerHeight,
        top: 0,
        left: 0
    },
    headerContent: {
        ...mixins.grid.fullWidth,
        ...mixins.grid.row,
        ...mixins.grid.justify,
        padding: '0 30px',
        [headerMobileBreakpoint]: {
            padding: '0 10px'
        }
    },
    headerLogoLink: {
        ...mixins.grid.row,
        ...mixins.grid.normalCenter,
        paddingTop: 5,
        [headerMobileBreakpoint]: {
            paddingTop: 0
        }
    },
    localeSettingsWrapper: {
        ...mixins.grid.row,
        ...mixins.grid.normalCenter,
        ...mixins.text.grey,
        ...mixins.text.s18,
        ...mixins.item.pointer
    },
    localeSettingsDisabled: {
        ...mixins.text.white,
        ...mixins.item.cursorDefault
    },
    headerContentBlock: {
        ...mixins.grid.row,
        ...mixins.grid.normalCenter
    },
    headerContentBlockHidden: {
        [headerMobileBreakpoint]: {
            ...mixins.item.dNone
        }
    },
    headerContentBlockSocials: {
        padding: '0 20px',
        [headerMobileBreakpoint]: {
            ...mixins.item.dNone
        }
    },
    headerBurgerWrapper: {
        padding: 10,
        borderRadius: '50%',
        ...mixins.item.white,
        ...mixins.item.dNone,
        ...mixins.item.pointer,
        [headerMobileBreakpoint]: {
            ...mixins.grid.row
        }
    },
    hiddenOnMob: {
        [headerMobileBreakpoint]: {
            ...mixins.item.dNone
        }
    },
    expandedMenu: {
        ...mixins.grid.w100,
        ...mixins.item.transition,
        ...mixins.item.hidden,
        ...mixins.item.black,
        maxWidth: 500,
        maxHeight: 0,
        borderRadius: '0px 0px 10px 10px'
    },
    expandedMenuMainMenu: {
        ...mixins.grid.col,
        ...mixins.grid.w50
    },
    expandedMenuSocials: {
        ...mixins.grid.col,
        ...mixins.grid.w50
    },
    centered: {
        ...mixins.grid.w100,
        ...mixins.item.dNone,
        ...mixins.item.abs,
        top: headerHeight,
        left: 0,
        [headerMobileBreakpoint]: {
            ...mixins.grid.rowCenter
        }
    },
    expandedMenuContent: {
        ...mixins.grid.row,
        padding: 10
    },
    expandedMenuOpen: {
        maxHeight: 300
    },
    expandedMenuButton: {
        ...mixins.grid.rowCenter,
        marginBottom: 20,
        marginTop: 20
    }
})

export default styles
