const flex = {
    display: 'flex'
}

const row = {
    ...flex,
    msFlexDirection: 'row',
    WebkitFlexDirection: 'row',
    WebkitBoxOrient: 'horizontal',
    WebkitBoxDirection: 'normal',
    flexDirection: 'row',
    WebkitFlexWrap: 'wrap',
    flexWrap: 'wrap',
    MozFlexWrap: 'wrap'
}
const col = {
    ...flex,
    '-ms-flex-direction': 'column',
    '-webkit-flex-direction': 'column',
    '-webkit-box-orient': 'vertical',
    '-webkit-box-direction': 'normal',
    'flex-direction': 'column'
}
const center = {
    '-ms-flex-pack': 'center',
    '-webkit-justify-content': 'center',
    '-webkit-box-pack': 'center',
    'justify-content': 'center'
}

const normal = {
    '-ms-flex-align': 'normal',
    '-webkit-align-items': 'normal',
    '-webkit-box-align': 'normal',
    'align-items': 'normal'
}

const normalCenter = {
    '-ms-flex-align': 'center',
    '-webkit-align-items': 'center',
    '-webkit-box-align': 'center',
    'align-items': 'center'
}

const grid = {
    full: {
        width: '100%',
        height: '100%'
    },
    fullWidth: {
        width: '100%'
    },
    fullHeight: {
        height: '100%'
    },
    flex: flex,
    space: {
        '-ms-flex': '1 1 0.000000001px',
        '-webkit-flex': '1',
        '-webkit-box-flex': '1',
        flex: '1'
    },
    spaceAround: {
        justifyContent: 'space-around'
    },
    noSpace: {
        '-ms-flex': 'none',
        '-webkit-flex': 'none',
        '-webkit-box-flex': 'none',
        flex: 'none'
    },
    wrap: {
        flexWrap: 'wrap!important'
    },
    noWrap: {
        flexWrap: 'nowrap!important'
    },
    row: row,
    col: col,
    justify: {
        '-ms-flex-pack': 'justify',
        '-webkit-justify-content': 'space-between',
        '-webkit-box-pack': 'justify',
        'justify-content': 'space-between'
    },
    center: center,
    normalCenter: normalCenter,
    rowCenter: {
        ...row,
        ...center,
        ...normalCenter
    },
    flexStart: {
        'align-self': 'flex-start'
    },
    flexEnd: {
        'align-self': 'flex-end'
    },
    colCenter: {
        ...col,
        ...center,
        ...normalCenter
    },
    colReverse: {
        flexDirection: 'column-reverse'
    },
    rowReverse: {
        flexDirection: 'row-reverse'
    },
    startJustify: {
        '-ms-flex-pack': 'start',
        '-webkit-justify-content': 'flex-start',
        'justify-content': 'flex-start'
    },
    endJustify: {
        '-ms-flex-pack': 'end',
        '-webkit-justify-content': 'flex-end',
        'justify-content': 'flex-end'
    },
    marginCenter: {
        margin: '0 auto'
    },
    iBlock: {
        display: 'inline-block'
    },
    justifyEnd: {
        justifyContent: 'flex-end'
    },
    justifyStart: {
        justifyContent: 'flex-start'
    },
    normal,
    ...(() => {
        let widths = {
            wAuto: {
                width: 'auto'
            }
        }

        for (let i = 0; i <= 20; i++) {
            widths[`w${i * 5}`] = { width: `${i * 5}%` }
        }

        return widths
    })()
}

export default grid
