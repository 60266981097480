import { StyleSheet } from 'aphrodite'
import { mixins } from 'theme'

import 'rc-slider/assets/index.css'

const styles = StyleSheet.create({
    slider: {
        ...mixins.grid.row,
        ...mixins.grid.normalCenter
    },
    sliderDescription: {
        ...mixins.grid.w100,
        ...mixins.text.center,
        ...mixins.text.s18,
        ...mixins.text.montserrat,
        color: '#F2F2F2',
        lineHeight: '22px',
        marginTop: 10,
        marginBottom: 10
    },
    sliderWrapper: {
        ...mixins.grid.row,
        ...mixins.grid.w100,
        ...mixins.item.rel,
        margin: '0 auto',
        boxSizing: 'border-box'
    },
    sliderValueWrapper: {
        ...mixins.grid.row,
        ...mixins.grid.normalCenter,
        ...mixins.grid.noWrap
    },
    sliderValue: {
        ...mixins.text.montserrat,
        ...mixins.text.s48,
        lineHeight: '59px',
        marginRight: 10
    }
})

export default styles
