import { StyleSheet } from 'aphrodite'
import { mixins, mobileBreakpointDefault, breakpoint1550, desktopBreakpoint } from 'theme'

const styles = StyleSheet.create({
    whatWrapper: {
        ...mixins.grid.row,
        ...mixins.item.black,
        justifyContent: 'center',
        fontFamily: 'Montserrat !important',
        fontSize: '20px !important',
        letterSpacing: '0.02em !important'
    },
    emptyBlock: {
        [desktopBreakpoint]: {
            height: '200px'
        }
    },
    li: {
        paddingBottom: '15px'
    },
    ul: {
        [breakpoint1550]: {
            textAlign: 'start !important'
        },
        [mobileBreakpointDefault]: {
            marginRight: '5%',
            textAlign: 'start !important'
        }
    },
    h1: {
        fontWeight: 600,
        fontSize: '48px',
        [mobileBreakpointDefault]: {
            fontSize: '32px',
            marginTop: 0
        }
    },
    boldP: {
        fontWeight: 600,
        padding: '10px 0'
    },
    backgroundGroup: {
        background: 'url(assets/images/InformationManagement/backgroundGroup.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }
})

export default styles
