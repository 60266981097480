import React, { useState } from 'react'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
import MaskedInput from 'react-maskedinput'
import Button from 'components/Button'
//import Checkbox from 'components/Checkbox'
import { toast } from 'react-toastify'

import validator from 'validator'
import translate from 'core/translate'

import { css } from 'aphrodite'
import styles from './styles'

const mapState = { center: [55.720951, 37.645591], zoom: 18 }
const sendFeedback = (body, callback) => {
    body = JSON.stringify(body)

    return fetch(window.spconfig.sendFeedbackUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: body
    })
        .then((res) => res.json())
        .then((response) => callback(response))
        .catch(() => callback({ error: true }))
}

const processingPhoneData = (phone) => {
    const numberPattern = /\d+/g
    phone = phone.match(numberPattern)

    if (phone) {
        phone = phone.join('')
    }

    return phone
}

const initialData = {
    name: '',
    email: '',
    phone: '',
    message: ''
}

const initialClientData = {
    company: '',
    site: ''
}

const Contacts = () => {
    const [data, setData] = useState({ ...initialData })
    const [errors, setErrors] = useState({})
    //const [isAgree, setIsAgree] = useState(false)
    const [loader, setLoader] = useState(false)

    const [companyData, setCompanyData] = useState({ ...initialClientData })
    const [errorsCompany, setErrorsCompany] = useState({})

    const onChangeInput = (event) => {
        const field = event.target.name
        let value = event.target.value

        if (field === 'phone') {
            value = processingPhoneData(value)
        }

        setData({
            ...data,
            [field]: value
        })
        setErrors({
            ...errors,
            [field]: false
        })
    }

    const onChangeCompanyInput = (event) => {
        const field = event.target.name
        let value = event.target.value

        setCompanyData({
            ...companyData,
            [field]: value
        })

        setErrorsCompany({
            ...errorsCompany,
            [field]: false
        })
    }

    /*  const onChangeAgree = () => {
        setIsAgree(!isAgree)
    }*/

    const validateFormData = () => {
        const errorsData = {}

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                if (!data[key]) {
                    errorsData[key] = true
                }
            }
        }

        if (data.phone && data.phone.length < 11) {
            errorsData.phone = true
        }

        if (!validator.isEmail(data.email)) {
            errorsData.email = true
        }

        setErrors(errorsData)
        return Object.keys(errorsData).length > 0
    }

    const validateFormCompanyData = () => {
        const errorsCompanyData = {}

        for (let key in companyData) {
            if (companyData.hasOwnProperty(key)) {
                if (!companyData[key]) {
                    errorsCompanyData[key] = true
                }
            }
        }

        setErrorsCompany(errorsCompanyData)
        return Object.keys(errorsCompanyData).length > 0
    }

    const onSubmitForm = (e) => {
        e.preventDefault()

        const validateError = validateFormData()
        const validateCompanyError = validateFormCompanyData()

        if (validateError) {
            return
        }

        if (validateCompanyError) {
            return
        }

        const dataToSave = {
            type: 'feedback',
            ...data,
            message:
                data.message +
                '. ' +
                translate('companySite') +
                `: ${companyData.site}` +
                '. ' +
                translate('companyName') +
                `: ${companyData.company}`
        }

        let toastId = toast.info(translate('messageIsSent'), { autoClose: false })
        setLoader(true)

        sendFeedback(dataToSave, (res) => {
            if (res.error) {
                toast.update(toastId, {
                    render: `${translate('messageSendError')} ${window.spconfig.salesEmail}`,
                    type: 'error',
                    autoClose: 5000
                })
            }
            if (!res.error) {
                toast.update(toastId, {
                    render: translate('messageSent'),
                    type: 'success',
                    autoClose: 5000
                })
            }

            setCompanyData({ ...initialClientData })
            setData({ ...initialData })
            setErrors({})
            //  setIsAgree(false)
            setLoader(false)
        })
    }

    return (
        <div id="contacts" className={css(styles.contactsWrapper)}>
            <div className={css(styles.contactsContent)}>
                <h2 className={css(styles.contactsTitle)}>{translate('contacts')}</h2>
                <div className={css(styles.contactsFormWrapper)}>
                    <div className={css(styles.contactsFormTitle)}>{translate('toContactUs')}</div>
                    <div className={css(styles.contactsFormContentWrapper)}>
                        <div className={css(styles.contactsFormContentLeft)}>
                            <input
                                className={css(styles.contactsFormInput, errors.name && styles.contactsFormInputError)}
                                type="text"
                                name="name"
                                onChange={onChangeInput}
                                value={data.name}
                                placeholder={translate('name')}
                            />
                            <input
                                className={css(styles.contactsFormInput, errors.email && styles.contactsFormInputError)}
                                type="text"
                                name="email"
                                onChange={onChangeInput}
                                value={data.email}
                                placeholder={translate('email')}
                            />
                            <MaskedInput
                                className={css(styles.contactsFormInput, errors.phone && styles.contactsFormInputError)}
                                type="text"
                                name="phone"
                                mask="+1 (111) 111-11-11"
                                onChange={onChangeInput}
                                value={data.phone}
                                placeholder={translate('phone')}
                            />
                            <input
                                className={css(
                                    styles.contactsFormInput,
                                    errorsCompany.company && styles.contactsFormInputError
                                )}
                                type="text"
                                name="company"
                                onChange={onChangeCompanyInput}
                                value={companyData.company}
                                placeholder={translate('companyName')}
                            />
                            <input
                                className={css(
                                    styles.contactsFormInput,
                                    errorsCompany.site && styles.contactsFormInputError
                                )}
                                type="text"
                                name="site"
                                onChange={onChangeCompanyInput}
                                value={companyData.site}
                                placeholder={translate('companySite')}
                            />
                        </div>
                        <div className={css(styles.contactsFormContentRight)}>
                            <textarea
                                className={css(
                                    styles.contactsFormInput,
                                    styles.contactsFormTextArea,
                                    errors.message && styles.contactsFormInputError
                                )}
                                placeholder={translate('message')}
                                rows="7"
                                value={data.message}
                                name="message"
                                onChange={onChangeInput}
                            />
                        </div>
                    </div>
                    <div className={css(styles.contactsFormFooter)}>
                        {/*
                        <Checkbox onClick={onChangeAgree} checked={isAgree} text={translate('agreeText')} />
                        */}
                        <Button
                            className={css(
                                styles.contactsFormFooterButton,
                                /*!isAgree ||*/ loader && styles.contactsFormFooterButtonDisabled
                            )}
                            onClick={onSubmitForm}
                            title={translate('send')}
                        />
                    </div>
                </div>
                <div className={styles.contactsMapWrapper}>
                    <div className={css(styles.contactsMapInfoWrapper)}>
                        {/*<div>*/}
                        {/*    <div className={css(styles.contactsMapInfoTitle)}>*/}
                        {/*        {translate('contactsForCommunication')}:*/}
                        {/*    </div>*/}

                        {/*    <a className={css(styles.contactsMapInfoDescription)} href={window.spconfig.phoneLink}>*/}
                        {/*        {window.spconfig.phone}*/}
                        {/*    </a>*/}
                        {/*    <a className={css(styles.contactsMapInfoAdditional)} href={`mailto:${window.spconfig.salesEmail}`}>*/}
                        {/*        {window.spconfig.salesEmail}*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                        {/*убрать пока офис не работает https://smartplayer.atlassian.net/browse/SPSITE-81 */}
                        <div style={{ display: 'none' }}>
                            <div className={css(styles.contactsMapInfoTitle)}>{translate('address')}:</div>
                            <div
                                className={css(styles.contactsMapInfoDescription)}
                                dangerouslySetInnerHTML={{ __html: translate('smartplayerOffice') }}
                            />
                        </div>

                        <div className={css(styles.contactsBlock)}>
                            <div className={css(styles.contactsMapInfoTitle)}>{translate('schedule')}</div>

                            <div className={css(styles.contactsMapInfoTwoColumns)}>
                                <div className={css(styles.contactsMapInfoLeftColumn)}>
                                    <div className={css(styles.contactsMapInfoAdditional)}>{translate('mon.fri')}</div>
                                    <div className={css(styles.contactsMapInfoDescription)}>9:00 - 18:00</div>
                                </div>

                                <div>
                                    <div className={css(styles.contactsMapInfoAdditional)}>{translate('sat.sun')}</div>
                                    <div className={css(styles.contactsMapInfoDescription)}>{translate('dayOff')}</div>
                                </div>
                            </div>
                        </div>
                        <div className={css(styles.contactsBlock)}>
                            <div className={css(styles.contactsMapInfoTitle)}>{translate('phone')}</div>
                            <a className={css(styles.contactsMapInfoAdditional)} href={window.spconfig.phoneLink}>
                                {window.spconfig.phone}
                            </a>
                            <div className={css(styles.contactsPhoneDesc)}>{translate('supportAboutProducts')}</div>
                        </div>
                        <div className={css(styles.contactsBlock)}>
                            <div className={css(styles.contactsMapInfoTitle)}>{translate('mail')}</div>
                            <a
                                className={css(styles.contactsMapInfoAdditional, styles.contactsMail)}
                                href={`mailto:${window.spconfig.salesEmail}`}
                            >
                                {window.spconfig.salesEmail}
                            </a>
                            <div className={css(styles.contactsPhoneDesc)}>{translate('supportForNewProject')}</div>
                        </div>
                    </div>
                    {/*<YMaps version="2.1">*/}
                    {/*    <Map defaultState={mapState} height="100%" width="100%">*/}
                    {/*        <Placemark*/}
                    {/*            geometry={[...mapState.center]}*/}
                    {/*            options={{*/}
                    {/*                iconLayout: 'default#image',*/}
                    {/*                iconImageHref: 'assets/images/Contacts/placemark.svg'*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </Map>*/}
                    {/*</YMaps>*/}
                </div>
            </div>
        </div>
    )
}

export default Contacts
