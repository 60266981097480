import React, { useState, useContext } from 'react'
import Button from 'components/Button'
import Modal from 'react-modal'
import Icon from 'components/Icon'
import MaskedInput from 'react-maskedinput'
//import Checkbox from 'components/Checkbox'
import { toast } from 'react-toastify'

import { AppContext } from 'components/App'
import validator from 'validator'
import translate from 'core/translate'

import { css } from 'aphrodite'
import styles from './styles'
import { isMobile } from 'theme'

Modal.setAppElement('#modal')

const videoUrls = {
    ru: 'https://www.youtube.com/embed/fc1mspAZKgk?autoplay=1',
    en: 'https://player.vimeo.com/video/337767507?autoplay=1'
}

const sendFeedback = (body, callback) => {
    body = JSON.stringify(body)

    return fetch(window.spconfig.sendFeedbackUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: body
    })
        .then((res) => res.json())
        .then((response) => callback(response))
        .catch(() => callback({ error: true }))
}

const processingPhoneData = (phone) => {
    const numberPattern = /\d+/g
    phone = phone.match(numberPattern)

    if (phone) {
        phone = phone.join('')
    }

    return phone
}

const initialData = {
    name: '',
    email: '',
    phone: ''
}

const Present = () => {
    const [isModalOpen, toggleModal] = useState(false)
    const [isVideoModalOpen, toggleVideoModal] = useState(false)
    const [data, setData] = useState({ ...initialData })
    const [errors, setErrors] = useState({})
    //const [isAgree, setIsAgree] = useState(false)
    const [loader, setLoader] = useState(false)
    const appContext = useContext(AppContext)
    const locale = appContext.locale

    const onToggleModal = () => {
        toggleModal(!isModalOpen)
    }
    const redirectToDemoServer = () => {
        const locationUrl = window.spconfig.demoServerUrl + '/#/registration?lang=' + locale
        window.location.href = locationUrl
    }
    const onToggleVideoModal = () => {
        toggleVideoModal(!isVideoModalOpen)
    }

    const onChangeInput = (event) => {
        const field = event.target.name
        let value = event.target.value

        if (field === 'phone') {
            value = processingPhoneData(value)
        }

        setData({
            ...data,
            [field]: value
        })
        setErrors({
            ...errors,
            [field]: false
        })
    }

    /*const onChangeAgree = () => {
        setIsAgree(!isAgree)
    }*/

    const validateFormData = () => {
        const errorsData = {}

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                if (!data[key]) {
                    errorsData[key] = true
                }
            }
        }

        if (data.phone && data.phone.length < 11) {
            errorsData.phone = true
        }

        if (!validator.isEmail(data.email)) {
            errorsData.email = true
        }

        setErrors(errorsData)
        return Object.keys(errorsData).length > 0
    }

    const onSubmitForm = (e) => {
        e.preventDefault()

        const validateError = validateFormData()

        if (validateError) {
            return
        }

        const dataToSave = {
            type: 'demo',
            ...data
        }

        let toastId = toast.info(translate('messageIsSent'), { autoClose: false })
        setLoader(true)

        sendFeedback(dataToSave, (res) => {
            if (res.error) {
                toast.update(toastId, {
                    render: `${translate('messageSendError')} ${window.spconfig.salesEmail}`,
                    type: 'error',
                    autoClose: 5000
                })
            }
            if (!res.error) {
                toast.update(toastId, {
                    render: translate('messageSent'),
                    type: 'success',
                    autoClose: 5000
                })
            }
            onToggleModal()
            setData({ ...initialData })
            setErrors({})
            //  setIsAgree(false)
            setLoader(false)
        })
    }

    const getVideoUrl = () => {
        return videoUrls[locale] ? videoUrls[locale] : videoUrls['en']
    }

    return (
        <div className={css(styles.wrapper)}>
            <video autoPlay muted loop className={css(styles.presentVideoBackground)}>
                <source src="assets/video/background.mp4" type="video/mp4" />
            </video>

            <div className={css(styles.contentWrapper)}>
                <div className={css(styles.content)}>
                    <div />
                    <div className={css(styles.presentTitleWrapper)}>
                        <div className={css(styles.presentTitleLogoWrapper)}>
                            <img
                                src="assets/images/Present/logo_big.svg"
                                alt="Smartplayer logo"
                                className={css(styles.presentTitleLogo)}
                            />
                        </div>
                        <h1 className={css(styles.presentTitle)}>{translate('mainDescription')}</h1>
                    </div>
                    <div className={css(styles.presentAdditionalWrapper)}>
                        <div className={css(styles.presentAdditionalButtons)}>
                            <Button
                                className={css(styles.presentAdditionalButton, styles.presentAdditionalButtonVideo)}
                                onClick={onToggleVideoModal}
                                title=""
                            >
                                <Icon type="playCircle" color="white" size={isMobile() ? 24 : 59} />
                                <div className={css(styles.presentAdditionalButtonText)}>
                                    {translate('watchAVideo')}
                                </div>
                            </Button>
                            <Button
                                title={translate('tryForFree')}
                                className={css(styles.presentAdditionalButton, styles.presentAdditionalButtonMargin)}
                                onClick={window.spconfig.demoServerUrl ? redirectToDemoServer : onToggleModal}
                            />
                        </div>
                        <div className={css(styles.presentAdditionalImageWrapper)}>
                            <img
                                src="assets/images/Present/notebook.png"
                                alt=""
                                className={css(styles.presentAdditionalImage)}
                            />
                        </div>
                    </div>
                </div>
                <div className={css(styles.decoration)}>
                    <img src="assets/images/Present/logo_present_decoration.svg" alt="" />
                </div>
            </div>

            <Modal closeTimeoutMS={300} isOpen={isModalOpen} contentLabel="modal" onRequestClose={onToggleModal}>
                <div className={css(styles.presentModalWrapper)}>
                    <div onClick={onToggleModal} className={css(styles.presentModalCloseIcon)}>
                        <Icon type="close" color="white" size={18} />
                    </div>
                    <div className={css(styles.modalContent)}>
                        <div className={css(styles.presentModalContentWrapper)}>
                            <div className={css(styles.presentModalContent)}>
                                <input
                                    className={css(
                                        styles.presentModalFormInput,
                                        errors.name && styles.presentModalFormInputError
                                    )}
                                    type="text"
                                    name="name"
                                    onChange={onChangeInput}
                                    value={data.name}
                                    placeholder={translate('name')}
                                />
                                <input
                                    className={css(
                                        styles.presentModalFormInput,
                                        errors.email && styles.presentModalFormInputError
                                    )}
                                    type="text"
                                    name="email"
                                    onChange={onChangeInput}
                                    value={data.email}
                                    placeholder={translate('email')}
                                />
                                <MaskedInput
                                    className={css(
                                        styles.presentModalFormInput,
                                        errors.phone && styles.presentModalFormInputError
                                    )}
                                    type="text"
                                    name="phone"
                                    mask="+1 (111) 111-11-11"
                                    onChange={onChangeInput}
                                    value={data.phone}
                                    placeholder={translate('phone')}
                                />
                                {/*  <div className={css(styles.presentModalCheckbox)}>
                                    <Checkbox onClick={onChangeAgree} checked={isAgree} text={translate('agreeText')} />
                                </div>
                                */}
                                <Button
                                    className={css(
                                        styles.presentModalFormFooterButton,
                                        /*!isAgree ||*/ loader && styles.presentModalFormFooterButtonDisabled
                                    )}
                                    onClick={onSubmitForm}
                                    title={translate('requestADemo')}
                                />
                            </div>
                        </div>
                        <div className={css(styles.presentModalContentWrapper)}>
                            <div className={css(styles.presentModalContent)}>
                                <div
                                    className={css(styles.presentModalDescription)}
                                    dangerouslySetInnerHTML={{ __html: translate('demoModalDescription') }}
                                />
                                <div className={css(styles.presentModalContentAdditionalDescription)}>
                                    {translate('demostrationDescription')}:
                                    <div className={css(styles.presentModalContentList)}>
                                        <div className={css(styles.presentModalContentLi)}>
                                            <div>
                                                <Icon type="li" color="grey" size={10} />
                                            </div>
                                            <div className={css(styles.presentModalContentLiText)}>
                                                {translate('demo.description1')}
                                            </div>
                                        </div>
                                        <div className={css(styles.presentModalContentLi)}>
                                            <div>
                                                <Icon type="li" color="grey" size={10} />
                                            </div>
                                            <div className={css(styles.presentModalContentLiText)}>
                                                {translate('demo.description2')}
                                            </div>
                                        </div>
                                        <div className={css(styles.presentModalContentLi)}>
                                            <div>
                                                <Icon type="li" color="grey" size={10} />
                                            </div>
                                            <div className={css(styles.presentModalContentLiText)}>
                                                {translate('demo.description3')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                closeTimeoutMS={300}
                isOpen={isVideoModalOpen}
                contentLabel="modal"
                onRequestClose={onToggleVideoModal}
            >
                <div className={css(styles.videoModalWrapper)}>
                    <div
                        onClick={onToggleVideoModal}
                        className={css(styles.presentModalCloseIcon, styles.presentVideoModalCloseIcon)}
                    >
                        <Icon type="close" color="white" size={32} />
                    </div>

                    <iframe
                        width="100%"
                        height="100%"
                        src={getVideoUrl()}
                        frameBorder="0"
                        title="SmartPlayer video"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                </div>
            </Modal>
        </div>
    )
}

export default Present
