import { StyleSheet } from 'aphrodite'
import { mixins, mobileBreakpoint } from 'theme'

const styles = StyleSheet.create({
    feedback: {
        ...mixins.grid.flex,
        ...mixins.grid.col,
        ...mixins.grid.normalCenter,
        width: '100vw',
        height: '100vh',
        fontFamily: 'Open Sans !important',
        letterSpacing: 1.2,
        background: '#fff',
        color: '#fff',
        overflowX: 'hidden'
    },
    feedback__container: {
        ...mixins.grid.flex,
        ...mixins.grid.col,
        ...mixins.grid.normalCenter,
        width: '100%',
        maxWidth: '550px'
    },
    feedback__header: {
        ...mixins.grid.flex,
        ...mixins.grid.col,
        ...mixins.grid.normalCenter,
        width: '100%',
        padding: '42px 60px',
        textAlign: 'center',
        [mobileBreakpoint]: {
            padding: '32px 60px 20px'
        },
        boxSizing: 'border-box'
    },
    feedback__title: {
        paddingTop: 27,
        fontSize: 18,
        fontWeight: 'bold',
        color: '#003688',
        textTransform: 'uppercase'
    },
    feedback__logo_type_header: {
        width: '325px',
        height: 'auto',
        [mobileBreakpoint]: {
            width: '198px'
        }
    },
    feedback__body: {
        ...mixins.grid.flex,
        ...mixins.grid.col,
        ...mixins.grid.normalCenter,
        ...mixins.grid.center,
        ...mixins.grid.space,
        padding: '60px 0 60px',
        [mobileBreakpoint]: {
            padding: '102px 0 134px'
        },
        width: '100%',
        background: '#F2F4F7',
        boxSizing: 'border-box'
    },
    feedback__info: {
        fontSize: 10
    },
    feedback__footer: {
        ...mixins.grid.flex,
        ...mixins.grid.col,
        ...mixins.grid.normalCenter,
        width: '100%',
        padding: '53px 22px 113px',
        [mobileBreakpoint]: {
            padding: '45px 22px 39px'
        },
        textAlign: 'center',
        background: '#272727',
        color: '#fff',
        boxSizing: 'border-box'
    },
    feedback__logo_type_footer: {
        width: '197px',
        height: 'auto',
        paddingTop: 58,
        [mobileBreakpoint]: {
            width: '155px',
            paddingTop: 38
        }
    },
    feedback__actions: {
        ...mixins.grid.flex,
        ...mixins.grid.row,
        ...mixins.grid.justify,
        ...mixins.grid.normalCenter,
        [mobileBreakpoint]: {
            ...mixins.grid.col
        },
        width: '100%',
        paddingBottom: 27
    },
    feedback__link: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'inherit',
        textDecoration: 'none',
        [mobileBreakpoint]: {
            order: 2,
            paddingTop: 25
        }
    },
    feedback__button: {
        padding: '7px 41px',
        fontSize: 12,
        color: 'inherit',
        border: '1px solid #fff',
        borderRadius: 5,
        textDecoration: 'none',
        ...mixins.item.transition,
        ':hover': {
            background: '#fff',
            color: '#272727'
        },
        [mobileBreakpoint]: {
            order: 1
        }
    }
})

export default styles
