import { StyleSheet } from 'aphrodite/no-important'
import { mixins } from 'theme'

const styles = StyleSheet.create({
    button: {
        ...mixins.text.s12,
        ...mixins.text.white,
        ...mixins.text.noUnderline,
        ...mixins.item.blueLight,
        ...mixins.item.pointer,
        ...mixins.item.transition,
        userSelect: 'none',
        borderRadius: 5,
        lineHeight: '16px',
        padding: '6px 12px',
        ':hover': {
            ...mixins.item.blue
        }
    }
})

export default styles
