import { StyleSheet } from 'aphrodite/no-important'
import { mobileBreakpointDefault, breakpoint1550 } from 'theme'

const styles = StyleSheet.create({
    block: {
        margin: '0 15%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: '1.5em',
        [breakpoint1550]: {
            margin: '0 10%'
        },
        [mobileBreakpointDefault]: {
            margin: '0 5%'
        }
    },
    blockImg: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    noImg: {
        [mobileBreakpointDefault]: {
            display: 'none'
        }
    },
    blockRightImg: {
        flexWrap: 'wrap',
        margin: '0 2% 0 15%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: '1.5em',
        [breakpoint1550]: {
            margin: '0 10%',
            justifyContent: 'center',
            paddingBottom: 0
        },
        [mobileBreakpointDefault]: {
            margin: '0 5%',
            justifyContent: 'center',
            paddingBottom: 0
        }
    },
    title: {
        color: '#FFB800',
        fontSize: '32px',
        fontWeight: 600
    },
    img: {
        display: 'flex',
        justifyContent: 'center',
        [mobileBreakpointDefault]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    textImgRight: {
        [mobileBreakpointDefault]: {
            width: '100% !important',
            textAlign: 'center',
            paddingTop: '5%'
        },
        [breakpoint1550]: {
            width: '100% !important',
            textAlign: 'center',
            paddingTop: '5%'
        }
    }
})

export default styles
