import { StyleSheet } from 'aphrodite'
import { mixins, mobileBreakpoint } from 'theme'

const styles = StyleSheet.create({
    whatWrapper: {
        ...mixins.grid.row,
        ...mixins.item.black
    },
    whatTitle: {
        ...mixins.grid.row,
        ...mixins.grid.w100,
        ...mixins.grid.center,
        ...mixins.text.montserrat,
        ...mixins.text.bold,
        ...mixins.text.s48,
        lineHeight: '59px',
        marginTop: '5%',
        [mobileBreakpoint]: {
            ...mixins.text.s24,
            lineHeight: '29px',
            marginTop: 0
        }
    },
    whatTitleContent: {
        ...mixins.grid.w50,
        ...mixins.text.center,
        [mobileBreakpoint]: {
            ...mixins.grid.w100,
            padding: 20
        }
    },
    whatContent: {
        ...mixins.grid.col,
        ...mixins.grid.w50,
        ...mixins.item.rel,
        [mobileBreakpoint]: {
            ...mixins.grid.w100,
            padding: 20
        }
    },
    whatContentImageWrapper: {
        ...mixins.grid.justifyEnd,
        [mobileBreakpoint]: {
            ...mixins.item.dNone
        }
    },
    whatContentImage: {
        width: '100%',
        height: 'auto'
    },
    whatContentDescriptionWrapper: {
        marginTop: 50,
        [mobileBreakpoint]: {
            marginTop: 0
        }
    },
    whatContentDescription: {
        ...mixins.grid.w80,
        ...mixins.text.montserrat,
        ...mixins.text.s14,
        lineHeight: '18px',
        letterSpacing: '0.02em',
        [mobileBreakpoint]: {
            ...mixins.grid.w100
        }
    },
    whatListWrapper: {
        marginTop: 20,
        marginBottom: 20
    },
    whatListItem: {
        ...mixins.grid.row,
        ...mixins.grid.noWrap,
        paddingBottom: 15
    },
    whatListItemTitle: {
        marginLeft: 15
    }
})

export default styles
