import { StyleSheet } from 'aphrodite'
import { mixins, mobileBreakpoint } from 'theme'

const styles = StyleSheet.create({
    wrapper: {
        ...mixins.grid.row
    },
    footerWrapper: {
        ...mixins.grid.col,
        ...mixins.grid.flexStart
    },
    header_mobWrapper: {
        ...mixins.grid.rowCenter
    },
    socialLink: {
        ...mixins.grid.row,
        ...mixins.grid.normalCenter,
        ...mixins.text.white,
        padding: '0 10px'
    },
    socialLinkIcon: {
        minWidth: 24
    },
    footerSocialLink: {
        ...mixins.grid.noWrap,
        ...mixins.text.noUnderline,
        marginTop: 20,
        [mobileBreakpoint]: {
            padding: 0
        }
    },
    header_mobSocialLink: {
        ...mixins.text.white
    },
    socialLinkText: {
        ...mixins.text.s18,
        paddingLeft: 15
    },
    footerSocialPhone: {
        ...mixins.text.s14,
        ...mixins.text.noUnderline,
        ...mixins.text.white,
        paddingLeft: 10,
        lineHeight: '19px',
        marginBottom: 5
    },
    header_mobSocialLinkText: {
        ...mixins.text.white,
        ...mixins.grid.w100,
        ...mixins.text.center,
        marginBottom: 10
    },
    description: {
        color: 'rgb(149 143 143)',
        fontSize: '12px',
        maxWidth: '280px',
        paddingLeft: '48px'
    },
    hideLink: {
        display: 'none !important'
    }
})

export default styles
