import React, { useState } from 'react'
import Icon from 'components/Icon'

import translate from 'core/translate'

import { css } from 'aphrodite'
import styles from './styles'

const config = {
    create: {
        background: 'assets/images/HowWorks/create_background.png',
        style: {
            backgroundPosition: 'left bottom'
        }
    },
    control: {
        background: 'assets/images/HowWorks/control_background.png',
        style: {
            backgroundPosition: 'left bottom'
        }
    },
    play: {
        background: 'assets/images/HowWorks/play_background.png',
        style: {
            backgroundPosition: 'right bottom'
        }
    },
    analytic: {
        background: 'assets/images/HowWorks/analytic_background.png',
        style: {
            backgroundPosition: 'right bottom'
        }
    },
    t(type, key) {
        return translate(`tabs.${type}.${key}`)
    }
}

const tabs = ['create', 'control', 'play', 'analytic']

const images = []

const preloadImage = () => {
    tabs.forEach((tab, idx) => {
        images[idx] = new Image()
        images[idx].src = config[tab].background
    })
}

preloadImage()

const HowWorks = () => {
    const [selectedTab, setSelectedTab] = useState({ idx: 0, id: 'create' })

    const onTabClick = (e) => {
        const tabId = e.currentTarget.dataset.tabid

        setSelectedTab({ idx: tabId, id: tabs[tabId] })
    }

    return (
        <div
            style={{
                ...config[selectedTab.id].style,
                backgroundImage: `url(${images[selectedTab.idx].src})`
            }}
            className={css(styles.howWorksWrapper)}
        >
            <div className={css(styles.howWorksWrapperContent)}>
                <h2 className={css(styles.howWorksTitle)}>{translate('tabs.title')}</h2>
                <div className={css(styles.howWorksTabsWrapper)}>
                    <div className={css(styles.howWorksTabsContent)}>
                        {tabs.map((tab, idx) => {
                            const active = tab === selectedTab.id
                            const opacity = active || idx <= selectedTab.idx ? 1 : 0.5

                            return (
                                <React.Fragment key={idx}>
                                    {0 !== idx && <div className={css(styles.howWorksDivider)} style={{ opacity }} />}
                                    <div className={css(styles.howWorksTabWrapper)}>
                                        <div className={css(styles.howWorksTabContent)}>
                                            <div
                                                className={css(styles.howWorksTab)}
                                                style={{ opacity }}
                                                data-tabid={idx}
                                                onClick={onTabClick}
                                            >
                                                <Icon type={tab} size={24} color="black" />
                                            </div>
                                        </div>
                                        <div
                                            style={{ display: active ? 'block' : 'none' }}
                                            className={css(styles.howWorksCirclesWrapper)}
                                        >
                                            <div
                                                className={css(styles.howWorksCircle)}
                                                style={{ animationDelay: '-2s' }}
                                            />
                                            <div
                                                className={css(styles.howWorksCircle)}
                                                style={{ animationDelay: '-1s' }}
                                            />
                                            <div
                                                className={css(styles.howWorksCircle)}
                                                style={{ animationDelay: '0s' }}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
                {(() => {
                    switch (selectedTab.id) {
                        case 'play':
                            return (
                                <div className={css(styles.howWorksContent)}>
                                    <div
                                        className={css(
                                            styles.howWorksContentBlock,
                                            styles.howWorksContentBlockAnalytic
                                        )}
                                    >
                                        <div className={css(styles.howWorksContentBlockTitle)}>
                                            {config.t(selectedTab.id, 'title')}
                                        </div>
                                        <div className={css(styles.howWorksContentBlockListWrapper)}>
                                            <span className={css(styles.howWorksContentBlockListTitle)}>
                                                {config.t(selectedTab.id, 'listTitle')}
                                            </span>
                                            <div className={css(styles.howWorksContentBlockListSubTitle)}>
                                                {config.t(selectedTab.id, 'listSubTitle')}
                                            </div>
                                            <div className={css(styles.howWorksContentBlockListItem)}>
                                                <div className={css(styles.howWorksContentBlockListNumber)}>1</div>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: config.t(selectedTab.id, 'listItem1')
                                                    }}
                                                />
                                            </div>
                                            <div className={css(styles.howWorksContentBlockListItem)}>
                                                <div className={css(styles.howWorksContentBlockListNumber)}>2</div>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: config.t(selectedTab.id, 'listItem2')
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div />
                                </div>
                            )
                        case 'analytic':
                            return (
                                <div className={css(styles.howWorksContent)}>
                                    <div
                                        className={css(
                                            styles.howWorksContentBlock,
                                            styles.howWorksContentBlockAnalytic
                                        )}
                                    >
                                        <div className={css(styles.howWorksContentBlockTitle)}>
                                            {config.t(selectedTab.id, 'title')}
                                        </div>
                                        <div className={css(styles.howWorksContentBlockListWrapper)}>
                                            <span className={css(styles.howWorksContentBlockListTitle)}>
                                                {config.t(selectedTab.id, 'listTitle')}
                                            </span>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: config.t(selectedTab.id, 'listItems')
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div />
                                </div>
                            )
                        case 'control':
                        default:
                            return (
                                <div className={css(styles.howWorksContent)}>
                                    <div />
                                    <div
                                        className={css(styles.howWorksContentBlock, styles.howWorksContentBlockCreate)}
                                    >
                                        <div className={css(styles.howWorksContentBlockTitle)}>
                                            {config.t(selectedTab.id, 'title')}
                                        </div>
                                        <div className={css(styles.howWorksContentBlockListWrapper)}>
                                            <span className={css(styles.howWorksContentBlockListTitle)}>
                                                {config.t(selectedTab.id, 'listTitle')}
                                            </span>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: config.t(selectedTab.id, 'listItems')
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                })()}
            </div>
        </div>
    )
}

export default HowWorks
