import LocalizedStrings from 'react-localization'
import translations from './translations.js'

export let strings

const translate = (key) => strings[key]

export const changeLocale = (locale, { initial } = {}) => {
    const defaultLocale = 'en'
    const lowCaseLocaleString = locale.toLowerCase()
    const getAvailableLanguages = strings.getAvailableLanguages()
    let availableLocales = getAvailableLanguages.find((language) => lowCaseLocaleString.includes(language))

    if (!availableLocales) {
        availableLocales = defaultLocale
    }

    if (!initial) {
        localStorage.setItem('smartplayerLocaleKey', availableLocales)
    }

    strings.setLanguage(availableLocales)
}

export const setLocaleOnInit = () => {
    let deviceLocale = localStorage.getItem('smartplayerLocaleKey')

    if (!deviceLocale) {
        deviceLocale = strings.getInterfaceLanguage()
    }

    changeLocale(deviceLocale, { initial: true })
}

export const initLocale = () => {
    strings = new LocalizedStrings(translations)

    setLocaleOnInit()
}

export default translate
