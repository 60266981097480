import React from 'react'
import { css } from 'aphrodite'
import styles from './styles'
import translate from 'core/translate'

const images = {
    checkbox: '/assets/images/Feedback/checkbox.svg',
    headerLogo: '/assets/images/Feedback/header__logo.svg',
    footerLogo: '/assets/images/Feedback/footer__logo.svg'
}

const Feedback = () => {
    return (
        <div className={css(styles.feedback)}>
            <div className={css(styles.feedback__header)}>
                <div className={css(styles.feedback__container)}>
                    <a href="https://smartplayer.ru/">
                        <img className={css(styles.feedback__logo_type_header)} src={images.headerLogo} />
                    </a>
                </div>
            </div>
            <div className={css(styles.feedback__body)}>
                <div className={css(styles.feedback__container)}>
                    <div className={css(styles.feedback__checkbox)}>
                        <img src={images.checkbox} />
                    </div>
                    <div className={css(styles.feedback__title)}>{translate('feedbackTitle')}</div>
                </div>
            </div>
            <div className={css(styles.feedback__footer)}>
                <div className={css(styles.feedback__container)}>
                    <div className={css(styles.feedback__actions)}>
                        <a href={window.spconfig.phoneLink} className={css(styles.feedback__link)}>
                            {window.spconfig.phone}
                        </a>

                        <a href={`mailto:${window.spconfig.salesEmail}`} className={css(styles.feedback__button)}>
                            {translate('feedbackButtonText')}
                        </a>
                    </div>
                    <div className={css(styles.feedback__info)}>{translate('feedbackDescription')}</div>
                    <a href="https://smartplayer.ru/">
                        <img className={css(styles.feedback__logo_type_footer)} src={images.footerLogo} />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Feedback
