import React from 'react'
import PropTypes from 'prop-types'
import ReactSlick from 'react-slick'

import { css } from 'aphrodite'
import styles from './styles'

const NextArrow = ({ className, onClick }) => (
    <img
        className={`${css(styles.arrow)} ${className}`}
        style={{ width: 16, height: 28, right: 10, zIndex: 1 }}
        src="assets/images/Carousel/arrow_next.svg"
        onClick={onClick}
        alt=""
    />
)

const PrevArrow = ({ className, onClick }) => (
    <img
        className={`${css(styles.arrow)} ${className}`}
        style={{ width: 16, height: 28, left: 10, zIndex: 1 }}
        src="assets/images/Carousel/arrow_prev.svg"
        onClick={onClick}
        alt=""
    />
)

const getCarouselSettings = (settingsFromProps) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: css(styles.carouselDots),
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        appendDots: (dots) => {
            const triggers = dots.map((dot, i) => (
                <div
                    key={i}
                    onClick={dot.props.children.props.onClick}
                    className={css(styles.carouselDotWrapper)}
                    style={{ width: `${85 / dots.length}%` }}
                >
                    <div className={css(styles.carouselDot, dot.props.className && styles.carouselDotActive)} />
                </div>
            ))

            return (
                <div>
                    <div className={css(styles.carouselDotsWrapper)}>
                        <div className={css(styles.carouselDotsContent)}>{triggers}</div>
                    </div>
                </div>
            )
        },
        ...settingsFromProps
    }

    return settings
}

const Carousel = ({ images, settings = {}, children, isRemovePaddingInMobile, ...props }) => {
    const carouselSettings = getCarouselSettings(settings)

    return (
        <ReactSlick {...carouselSettings}>
            {images &&
                images.length > 0 &&
                images.map((image, i) => (
                    <div className={css(styles.carouselImageWrapper)} key={i}>
                        <div className={css(styles.carouselImageContent)}>
                            <img className={css(styles.carouselImage)} src={image} alt="" />
                        </div>
                    </div>
                ))}
            {children &&
                React.Children.count(children) > 0 &&
                React.Children.toArray(children).map((child, i) => {
                    return (
                        <div className={css(styles.carouselImageWrapper)} key={i}>
                            <div
                                className={css(
                                    styles.carouselImageContent,
                                    isRemovePaddingInMobile && styles.carouselImageContentMobile
                                )}
                            >
                                {child}
                            </div>
                        </div>
                    )
                })}
        </ReactSlick>
    )
}

Carousel.propTypes = {
    images: PropTypes.array.isRequired,
    isRemovePaddingInMobile: PropTypes.bool
}

export default Carousel
