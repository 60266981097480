import { StyleSheet } from 'aphrodite'
import { mixins, mobileBreakpoint } from 'theme'

const styles = StyleSheet.create({
    menuWrapper: {
        ...mixins.grid.row,
        margin: 0,
        listStyle: 'none'
    },
    verticalMenuWrapper: {
        ...mixins.grid.col,
        padding: 0
    },
    menuItem: {
        padding: '0 15px'
    },
    verticalMenuItem: {
        padding: '20px 0',
        [mobileBreakpoint]: {
            padding: '10px 0'
        }
    },
    menuItemLink: {
        ...mixins.item.transition,
        ...mixins.text.low,
        ...mixins.text.white,
        ...mixins.text.s18,
        ':hover': {
            ...mixins.text.blueLight
        }
    },
    verticalMenuItemLink: {
        ...mixins.text.s14,
        ...mixins.text.up,
        ...mixins.text.noUnderline,
        lineHeight: '18px',
        ...mixins.item.pointer
    },
    menuItemActive: {
        ...mixins.text.noUnderline,
        ...mixins.text.blueLight,
        ...mixins.item.cursorDefault
    },
    moduleItem: {
        ...mixins.grid.row,
        ...mixins.text.grey,
        ...mixins.text.s18,
        paddingBottom: '10px'
    }
})

export default styles
