import { StyleSheet } from 'aphrodite'
import { mixins, mobileBreakpoint } from 'theme'

const scaleIn = {
    from: {
        transform: 'scale(.5, .5)',
        opacity: 0.5
    },
    to: {
        transform: 'scale(2.5, 2.5)',
        opacity: 0
    }
}

const styles = StyleSheet.create({
    howWorksWrapper: {
        ...mixins.grid.fullHeight,
        ...mixins.item.rel,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        [mobileBreakpoint]: {
            background: 'none'
        }
    },
    howWorksWrapperContent: {
        padding: '5%',
        [mobileBreakpoint]: {
            ...mixins.grid.row,
            padding: 20
        }
    },
    howWorksTitle: {
        ...mixins.grid.w50,
        ...mixins.text.montserrat,
        ...mixins.text.bold,
        ...mixins.text.s48,
        ...mixins.text.center,
        margin: '0 auto',
        lineHeight: '59px',
        letterSpacing: '0.1em',
        marginBottom: 50,
        [mobileBreakpoint]: {
            ...mixins.grid.w100,
            ...mixins.text.s24,
            lineHeight: '29px',
            marginBottom: 20
        }
    },
    howWorksTabsWrapper: {
        ...mixins.grid.rowCenter,
        ...mixins.grid.w100
    },
    howWorksTabsContent: {
        ...mixins.grid.row,
        ...mixins.grid.normalCenter
    },
    howWorksTab: {
        ...mixins.item.abs,
        ...mixins.item.blue,
        ...mixins.item.pointer,
        padding: 18,
        borderRadius: '50%',
        zIndex: 2,
        top: 0,
        left: 0,
        [mobileBreakpoint]: {
            padding: 11
        }
    },
    howWorksTabContent: {
        ...mixins.item.rel,
        width: 60,
        height: 60,
        [mobileBreakpoint]: {
            width: 46,
            height: 46
        }
    },
    howWorksDivider: {
        ...mixins.item.blue,
        width: 100,
        height: 2,
        [mobileBreakpoint]: {
            width: 20
        }
    },
    howWorksTabWrapper: {
        ...mixins.item.rel
    },
    howWorksCirclesWrapper: {
        boxSizing: 'border-box'
    },
    howWorksCircle: {
        ...mixins.item.abs,
        ...mixins.item.blue,
        borderRadius: '50%',
        zIndex: 0,
        opacity: 0,
        top: 0,
        left: 0,
        width: 60,
        height: 60,
        animationName: [scaleIn],
        animationDuration: '4s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'cubic-bezier(0.36, 0.11, 0.89, 0.32)',
        [mobileBreakpoint]: {
            width: 46,
            height: 46
        }
    },
    howWorksContent: {
        ...mixins.grid.row,
        ...mixins.grid.justify
    },
    howWorksContentBlock: {
        marginTop: 80,
        [mobileBreakpoint]: {
            marginTop: 40
        }
    },
    howWorksContentBlockCreate: {
        ...mixins.grid.w50,
        [mobileBreakpoint]: {
            ...mixins.grid.w100
        }
    },
    howWorksContentBlockAnalytic: {
        ...mixins.grid.w40,
        marginLeft: '10%',
        [mobileBreakpoint]: {
            ...mixins.grid.w100,
            marginLeft: 0
        }
    },
    howWorksContentBlockTitle: {
        ...mixins.grid.w80,
        ...mixins.text.s36,
        ...mixins.text.white,
        ...mixins.text.montserrat,
        lineHeight: '44px',
        [mobileBreakpoint]: {
            ...mixins.grid.w100,
            ...mixins.text.s24,
            lineHeight: '29px'
        }
    },
    howWorksContentBlockListWrapper: {
        ...mixins.text.montserrat,
        ...mixins.text.s24,
        ...mixins.text.white,
        lineHeight: '50px',
        marginTop: 60,
        marginBottom: 60,
        [mobileBreakpoint]: {
            ...mixins.text.s18,
            lineHeight: '45px',
            marginBottom: 20,
            marginTop: 20
        }
    },
    howWorksContentBlockListTitle: {
        ...mixins.text.blue,
        ...mixins.text.bold,
        lineHeight: '36px'
    },
    howWorksContentBlockListItem: {
        ...mixins.grid.row,
        ...mixins.grid.normalCenter,
        lineHeight: '36px',
        marginBottom: 20,
        marginTop: 20
    },
    howWorksContentBlockListNumber: {
        ...mixins.text.s80,
        ...mixins.text.blue,
        ...mixins.text.center,
        lineHeight: '50px',
        marginRight: 10,
        width: 50
    }
})

export default styles
