import themeConfig from 'theme/themeConfig'

const item = {
    fixed: {
        position: 'fixed'
    },
    abs: {
        position: 'absolute'
    },
    rel: {
        position: 'relative'
    },
    cursorDefault: {
        cursor: 'default'
    },
    pointer: {
        cursor: 'pointer'
    },
    transition: {
        transition: 'all 0.3s ease-in-out'
    },
    hidden: {
        overflow: 'hidden'
    },
    dNone: {
        display: 'none'
    },
    ...(() => {
        const itemStyles = {}

        for (let key in themeConfig.colors) {
            if (themeConfig.colors.hasOwnProperty(key)) {
                itemStyles[key] = {
                    backgroundColor: themeConfig.colors[key]
                }
            }
        }

        return itemStyles
    })()
}

export default item
