import { StyleSheet } from 'aphrodite'
import { mixins, mobileBreakpoint } from 'theme'

import { headerHeight } from 'components/Header/styles.js'

const styles = StyleSheet.create({
    solutionsWrapper: {
        ...mixins.item.rel,
        padding: '5%',
        height: `calc(100% - ${headerHeight}px)`,
        [mobileBreakpoint]: {
            background: 'linear-gradient(180deg, #353890 0%, #0E58DA 100%)',
            height: '100%',
            padding: 20
        }
    },
    solutionsInteractiveImageWrapper: {
        ...mixins.grid.w100,
        margin: '0 auto',
        marginTop: 20,
        [mobileBreakpoint]: {
            ...mixins.item.dNone
        }
    },
    solutionsCarouselContent: {
        width: '100%',
        maxWidth: '928px',
        ...mixins.item.abs,
        background: 'rgba(0, 0, 0, 0.8)',
        borderRadius: 5,
        bottom: 20,
        right: 20,
        [mobileBreakpoint]: {
            ...mixins.grid.w100,
            ...mixins.item.rel,
            margin: 0,
            marginTop: 0,
            paddingTop: 52,
            bottom: 0,
            right: 0,
            background: 'transparent'
        }
    },
    solutionsCarouselSlide: {
        width: '100%',
        padding: '25px 0 10px 50px',
        boxSizing: 'border-box',
        [mobileBreakpoint]: {
            padding: '0 4px 35px 4px'
        }
    },
    solutionsCarouselInfo: {},
    solutionsCarouselTitle: {
        paddingLeft: 30,
        ...mixins.text.montserrat,
        fontSize: 36,
        fontWeight: 'bold',
        lineHeight: '44px',
        [mobileBreakpoint]: {
            paddingBottom: 27,
            paddingLeft: 0,
            fontSize: 24,
            lineHeight: '29px',
            textAlign: 'center'
        }
    },
    solutionsCarouselTitlePosition1: {
        paddingBottom: 90
    },
    solutionsCarouselTitlePosition2: {
        paddingBottom: 60
    },
    solutionsCarouselTitlePosition3: {
        paddingBottom: 100
    },
    solutionsCarouselTitlePosition4: {
        paddingBottom: 31
    },
    solutionsCarouselTitlePosition5: {
        paddingBottom: 122
    },
    solutionsCarouselTitlePosition6: {
        paddingBottom: 90
    },
    solutionsCarouselList: {
        width: '100%',
        [mobileBreakpoint]: {
            maxWidth: '100%'
        }
    },
    solutionsCarouselListPosition1: {
        maxWidth: 356
    },
    solutionsCarouselListPosition2: {
        maxWidth: 356
    },
    solutionsCarouselListPosition3: {
        maxWidth: 356
    },
    solutionsCarouselListPosition4: {
        maxWidth: 437
    },
    solutionsCarouselListPosition5: {
        maxWidth: 356
    },
    solutionsCarouselListPosition6: {
        maxWidth: 356
    },
    solutionsCarouselText: {
        ...mixins.item.rel,
        paddingLeft: 30,
        paddingBottom: 21,
        fontSize: 14,
        ...mixins.text.montserrat,
        [mobileBreakpoint]: {
            fontSize: 11,
            paddingLeft: 23
        }
    },
    solutionsCarouselIcon: {
        ...mixins.item.abs,
        left: 0,
        top: 1,
        width: 15,
        height: 15,
        background: `url('assets/images/Solutions/listIcon.svg') center center no-repeat`,
        backgroundSize: 'contain',
        [mobileBreakpoint]: {
            width: 12,
            height: 12,
            top: 0
        }
    },
    solutionsCarouselImage: {
        ...mixins.item.abs,
        maxWidth: '100%',
        height: 'auto',
        [mobileBreakpoint]: {
            ...mixins.item.rel,
            top: 0,
            left: 0,
            display: 'block',
            margin: '0 auto'
        }
    },
    solutionsCarouselImagePosition1: {
        top: 60,
        right: 30
    },
    solutionsCarouselImagePosition2: {
        top: 72,
        right: 0
    },
    solutionsCarouselImagePosition3: {
        top: 70,
        right: 40
    },
    solutionsCarouselImagePosition4: {
        top: 60,
        right: 0
    },
    solutionsCarouselImagePosition5: {
        top: 74,
        right: 0
    },
    solutionsCarouselImagePosition6: {
        top: 40,
        right: 5
    }
})

export default styles
