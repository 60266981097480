import React from 'react'
import PropTypes from 'prop-types'

import { css } from 'aphrodite'
import styles from './styles'

const TextAndImage = ({
    text,
    className,
    image,
    textWidth,
    imageWidth,
    title,
    blockRightImg,
    blockImg,
    order,
    firstTextImg,
    noImgInMobile,
    ...props
}) => (
    <div className={className} {...props}>
        <span
            className={blockRightImg ? css(styles.blockRightImg) : blockImg ? css(styles.blockImg) : css(styles.block)}
        >
            <span style={{ width: textWidth, zIndex: 1 }} className={blockRightImg ? css(styles.textImgRight) : ''}>
                <span className={css(styles.title)}>{title}</span>
                {text}
            </span>
            <span className={noImgInMobile ? css(styles.noImg) : css(styles.img)}>
                <img style={{ width: imageWidth }} src={image} />
            </span>
        </span>
    </div>
)

TextAndImage.propTypes = {
    text: PropTypes.object,
    blockRightImg: PropTypes.bool,
    blockImg: PropTypes.bool,
    noImgInMobile: PropTypes.bool,
    firstTextImg: PropTypes.bool,
    image: PropTypes.string,
    textWidth: PropTypes.string,
    imageWidth: PropTypes.string,
    order: PropTypes.number,
    className: PropTypes.string
}

export default TextAndImage
