import { StyleSheet } from 'aphrodite'
import { mixins, mobileBreakpoint } from 'theme'

const styles = StyleSheet.create({
    trustWrapper: {
        background: 'url(assets/images/Trust/pattern.png)',
        padding: '5%',
        [mobileBreakpoint]: {
            padding: 20
        }
    },
    trustContent: {
        ...mixins.grid.w70,
        ...mixins.item.rel,
        margin: '0 auto',
        marginTop: '3%',
        borderRadius: 10,
        [mobileBreakpoint]: {
            ...mixins.grid.w100
        }
    },
    trustContentLayer: {
        ...mixins.item.abs,
        ...mixins.grid.full,
        zIndex: 0,
        top: 0,
        left: 0,
        background: 'linear-gradient(178.72deg, #181F2C -7.45%, #0E58DA 118.22%)',
        opacity: 0.2
    },
    trustLogosWrapper: {
        ...mixins.item.rel,
        zIndex: 2
    },
    trustLogosContent: {
        ...mixins.text.center
    },
    trustLogosImage: {
        ...mixins.grid.w100,
        height: 'auto',
        padding: '0 20px',
        [mobileBreakpoint]: {
            padding: '0 10px'
        }
    },
    hiddenOnMobile: {
        [mobileBreakpoint]: {
            ...mixins.item.dNone
        }
    },
    showOnMobile: {
        ...mixins.item.dNone,
        [mobileBreakpoint]: {
            display: 'block'
        }
    }
})

export default styles
