import { StyleSheet } from 'aphrodite'
import { mixins } from 'theme'

import { headerHeight } from 'components/Header/styles.js'

const styles = StyleSheet.create({
    contentWrapper: {
        ...mixins.grid.full,
        ...mixins.text.openSans
    },
    content: {
        ...mixins.item.black,
        ...mixins.text.white,
        marginTop: headerHeight
    },
    solutionsWrapper: {
        background: 'url(assets/images/Solutions/background.png)',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }
})

export default styles
