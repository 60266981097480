import { StyleSheet } from 'aphrodite'
import { mixins, mobileBreakpoint } from 'theme'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const styles = StyleSheet.create({
    carouselImageWrapper: {
        outline: 'none'
    },
    carouselImageContent: {
        ...mixins.item.rel,
        padding: 20
    },
    carouselImageContentMobile: {
        [mobileBreakpoint]: {
            padding: 0
        }
    },
    carouselImage: {
        ...mixins.grid.w100,
        height: 'auto',
        top: 0,
        left: 0
    },
    carouselDotsWrapper: {
        ...mixins.grid.fullWidth,
        ...mixins.grid.rowCenter
    },
    carouselDotsContent: {
        ...mixins.grid.w80,
        ...mixins.grid.rowCenter
    },
    carouselDotWrapper: {
        ...mixins.item.pointer,
        maxWidth: 120,
        paddingRight: 5,
        ':last-child': {
            paddingRight: 0
        }
    },
    carouselDot: {
        ...mixins.grid.w100,
        ...mixins.item.blueLight,
        ...mixins.item.transition,
        opacity: 0.3,
        height: 4
    },
    carouselDotActive: {
        ...mixins.item.blue,
        opacity: 1
    },
    carouselDots: {
        ...mixins.item.abs,
        ...mixins.item.abs,
        ...mixins.grid.w100,
        ...mixins.text.center,
        bottom: 15
    },
    arrow: {
        [mobileBreakpoint]: {
            ...mixins.item.dNone
        }
    }
})

export default styles
