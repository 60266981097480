import React, { useState, useContext } from 'react'
import { AppContext } from 'components/App'
import Menu from 'components/Menu'
import Socials from 'components/Socials'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { Link } from 'react-router'
import translate from 'core/translate'
import { css } from 'aphrodite'
import styles from './styles'

const LocaleSettings = () => {
    const appContext = useContext(AppContext)

    const changeLocale = (e) => {
        appContext.onChangeLocale(e.target.dataset.locale)
    }

    let langArray = window.spconfig.languages

    const IsOneInArray = (e) => {
        if (langArray.length === 1) {
            return true
        }
    }

    return (
        <div className={css(styles.localeSettingsWrapper)}>
            {!IsOneInArray() && (
                <>
                    {langArray.includes('ru') && (
                        <>
                            <div
                                className={css(appContext.locale.includes('ru') && styles.localeSettingsDisabled)}
                                onClick={changeLocale}
                                data-locale="ru"
                            >
                                Ru
                            </div>
                            {langArray.includes('ru') && <>/</>}
                        </>
                    )}
                    {langArray.includes('en') && (
                        <>
                            <div
                                className={css(appContext.locale.includes('en') && styles.localeSettingsDisabled)}
                                onClick={changeLocale}
                                data-locale="en"
                            >
                                En
                            </div>
                            {(langArray.includes('pt') || langArray.includes('br') || langArray.includes('es')) && (
                                <>/</>
                            )}
                        </>
                    )}
                    {langArray.includes('br') ||
                        (langArray.includes('pt') && (
                            <>
                                <div
                                    className={css(
                                        (appContext.locale.includes('br') || appContext.locale.includes('pt')) &&
                                            styles.localeSettingsDisabled
                                    )}
                                    onClick={changeLocale}
                                    data-locale="pt"
                                >
                                    Pt
                                </div>
                                {langArray.includes('es') && <>/</>}
                            </>
                        ))}
                    {langArray.includes('es') && (
                        <>
                            <div
                                className={css(appContext.locale.includes('es') && styles.localeSettingsDisabled)}
                                onClick={changeLocale}
                                data-locale="es"
                            >
                                Es
                            </div>
                            {langArray.includes('kz') && <>/</>}
                        </>
                    )}
                    {langArray.includes('kz') && (
                        <>
                            <div
                                className={css(appContext.locale.includes('kz') && styles.localeSettingsDisabled)}
                                onClick={changeLocale}
                                data-locale="kz"
                            >
                                Kz
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    )
}

const Header = () => {
    const [isBurgerOpen, toggleBurger] = useState(false)
    const appContext = useContext(AppContext)
    const language = appContext.locale

    const onToggleBurgerState = () => {
        toggleBurger(!isBurgerOpen)
    }

    return (
        <header className={css(styles.header)}>
            <div className={css(styles.headerContent)}>
                <div className={css(styles.headerBurgerWrapper)} onClick={onToggleBurgerState}>
                    <Icon type={isBurgerOpen ? 'close' : 'burger'} color="black" />
                </div>
                <div className={css(styles.headerContentBlock)}>
                    <Link className={css(styles.headerLogoLink)} to="/" rel="noopener noreferrer">
                        <img src="assets/images/Header/logo.svg" alt="SmartPlayer - No limits only your imagination" />
                    </Link>
                    <div className={css(styles.headerContentBlockHidden)}>
                        <Menu id="main_header" />
                    </div>
                </div>
                <div className={css(styles.hiddenOnMob)} />
                <div className={css(styles.headerContentBlock)}>
                    <LocaleSettings />
                    <div className={css(styles.headerContentBlockSocials)}>
                        <Socials position="header" />
                    </div>
                    <div className={css(styles.headerContentBlockHidden)}>
                        <Button
                            title={translate('login')}
                            target="_blank"
                            href={`${window.spconfig.cmsLoginAddress}${language}`}
                        />
                    </div>
                </div>
            </div>
            <div className={css(styles.centered)}>
                <div className={css(styles.expandedMenu, isBurgerOpen && styles.expandedMenuOpen)}>
                    <div className={css(styles.expandedMenuContent)}>
                        <div className={css(styles.expandedMenuMainMenu)}>
                            <Menu id="main_header" type="vertical" onClick={onToggleBurgerState} />
                        </div>
                        <div className={css(styles.expandedMenuSocials)}>
                            <Socials position="header_mob" />
                            <div className={css(styles.expandedMenuButton)}>
                                <Button
                                    title={translate('login')}
                                    target="_blank"
                                    href={`${window.spconfig.cmsLoginAddress}${language}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
