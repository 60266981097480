import { StyleSheet } from 'aphrodite'
import { mixins, mobileBreakpoint } from 'theme'

const styles = StyleSheet.create({
    pricesWrapper: {
        ...mixins.text.montserrat,
        padding: '5%',
        background: 'url(assets/images/Prices/background.png)',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        [mobileBreakpoint]: {
            padding: 20
        }
    },
    pricesContentWrapper: {
        ...mixins.grid.w70,
        margin: '0 auto',
        [mobileBreakpoint]: {
            ...mixins.grid.w100
        }
    },
    pricesHeader: {
        ...mixins.grid.row,
        ...mixins.grid.w100,
        ...mixins.grid.justify,
        ...mixins.text.s18,
        ...mixins.text.white,
        lineHeight: '19px',
        [mobileBreakpoint]: {
            ...mixins.item.dNone
        }
    },
    pricesHeaderBoldText: {
        ...mixins.text.s24,
        lineHeight: '29px',
        [mobileBreakpoint]: {
            ...mixins.text.s12,
            lineHeight: '14px'
        }
    },
    pricesColMax: {
        ...mixins.grid.w60,
        [mobileBreakpoint]: {
            ...mixins.grid.w100,
            marginBottom: 20
        }
    },
    pricesCol: {
        ...mixins.grid.w20
    },
    pricesContent: {
        ...mixins.grid.row,
        ...mixins.grid.w100,
        ...mixins.grid.justify,
        ...mixins.grid.normalCenter,
        ...mixins.text.s18,
        ...mixins.text.white,
        boxSizing: 'border-box',
        lineHeight: '22px',
        marginTop: 10,
        background: 'rgba(25, 25, 25, 0.9)',
        borderRadius: 10,
        padding: 20,
        boxShadow: '0px 15px 30px rgba(112, 0, 255, 0.1)',
        [mobileBreakpoint]: {
            padding: '17px 32px'
        }
    },
    pricesContentMargin: {
        marginTop: 100,
        padding: 30,
        [mobileBreakpoint]: {
            marginTop: 50
        }
    },
    pricesContentGradientText: {
        ...mixins.text.bold,
        ...mixins.text.s24,
        lineHeight: '29px',
        background: '-webkit-linear-gradient(86.13deg, #2EB4FF -11.69%, #C070FF 109.6%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent'
    },
    pricesCalculatorTotalWrapper: {
        ...mixins.grid.w100,
        ...mixins.text.center,
        marginTop: 10
    },
    pricesCalculatorTotal: {
        ...mixins.text.bold,
        ...mixins.text.s48,
        lineHeight: '59px',
        background: '-webkit-linear-gradient(86.13deg, #2EB4FF -11.69%, #C070FF 109.6%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent'
    },
    pricesContentDesc: {
        ...mixins.grid.w100,
        [mobileBreakpoint]: {
            ...mixins.text.s14,
            lineHeight: '19px'
        }
    },
    pricesCalculatorWrapper: {
        ...mixins.grid.w100
    },
    pricesCalculatorTitle: {
        ...mixins.grid.w100,
        ...mixins.text.s24,
        lineHeight: '29px',
        marginBottom: 15
    },
    pricesCalculatorSettings: {
        ...mixins.grid.w100,
        ...mixins.grid.row,
        ...mixins.grid.normalCenter,
        ...mixins.grid.justify,
        ...mixins.grid.noWrap,
        [mobileBreakpoint]: {
            ...mixins.grid.wrap,
            ...mixins.grid.col,
            ...mixins.grid.normal
        }
    },
    pricesCalculatorTabsWrapper: {
        marginTop: 40
    },
    pricesCalculatorTab: {
        ...mixins.text.bold,
        ...mixins.text.s24,
        ...mixins.text.grey,
        ...mixins.item.pointer,
        padding: 10,
        lineHeight: '29px'
    },
    pricesCalculatorTabActive: {
        ...mixins.text.blue,
        ...mixins.item.cursorDefault
    },
    pricesCalculatorSettingsContent: {
        ...mixins.grid.row,
        marginRight: 20,
        [mobileBreakpoint]: {
            ...mixins.grid.w100,
            margin: 0
        }
    },
    pricesCalculatorSettingsContentCheckbox: {
        padding: 10,
        [mobileBreakpoint]: {
            ...mixins.grid.w100
        }
    },
    pricesCalculatorSlider: {
        ...mixins.item.transition,
        ...mixins.item.hidden,
        padding: 10,
        maxHeight: 0
    },
    pricesCalculatorSliderShow: {
        maxHeight: 500
    },
    pricesColContent: {
        ...mixins.grid.w50
    },
    pricesColTitlesPaddingLeft: {
        [mobileBreakpoint]: {
            paddingLeft: 10
        }
    },
    pricesColsWrapper: {
        ...mixins.grid.row,
        ...mixins.grid.w40,
        [mobileBreakpoint]: {
            ...mixins.grid.justify,
            ...mixins.grid.w100
        }
    },
    pricesColTitles: {
        ...mixins.item.dNone,
        [mobileBreakpoint]: {
            ...mixins.grid.row,
            ...mixins.text.s12,
            lineHeight: '14px'
        }
    }
})

export default styles
