import React, { useState, useEffect, useContext, createContext } from 'react'
import { IndexRoute, Router, Route, browserHistory } from 'react-router'
import { ToastContainer } from 'react-toastify'

// глобальные элементы
import Header from 'components/Header'
import Footer from 'components/Footer'

// blocks
import Present from 'blocks/Present'
import WhatIsASmartplayer from 'blocks/WhatIsASmartplayer'
import InformationManagementStart from 'blocks/InformationManagementStart'
import Solutions from 'blocks/Solutions'
import WhySmartplayer from 'blocks/WhySmartplayer'
import HowWorks from 'blocks/HowWorks'
import Trust from 'blocks/Trust'
import Contacts from 'blocks/Contacts'

// pages
import Prices from 'blocks/Prices'
import Feedback from 'blocks/Feedback'

import { anchors } from 'components/Menu'
import { changeLocale, strings } from 'core/translate'

import { css } from 'aphrodite'
import styles from './styles.js'

export const AppContext = createContext()

const Content = () => {
    const appContext = useContext(AppContext)

    useEffect(() => {
        if (appContext.anchor && anchors[appContext.anchor]) {
            const activeAnchor = anchors[appContext.anchor]
            const anchorLink = document.getElementById(`anchor_${activeAnchor}`)

            anchorLink.click()
        }
    }, [appContext.anchor])

    return (
        <React.Fragment>
            <Present />
            <WhatIsASmartplayer />
            <div className={css(styles.solutionsWrapper)}>
                <Solutions />
                <WhySmartplayer />
            </div>
            <HowWorks />
            <Trust />
            <Contacts />
        </React.Fragment>
    )
}

const InformationManagement = () => {
    const appContext = useContext(AppContext)

    useEffect(() => {
        if (appContext.anchor && anchors[appContext.anchor]) {
            const activeAnchor = anchors[appContext.anchor]
            const anchorLink = document.getElementById(`anchor_${activeAnchor}`)

            anchorLink.click()
        }
    }, [appContext.anchor])

    return (
        <React.Fragment>
            <InformationManagementStart />
        </React.Fragment>
    )
}

const AppLayer = ({ children }) => (
    <div className={css(styles.contentWrapper)}>
        <Header />
        <div className={css(styles.content)}>
            {children}
            <Footer />
        </div>
        <ToastContainer />
    </div>
)

const App = () => {
    const [reloadState, forceUpdate] = useState(false)
    const [anchor, setAnchor] = useState(null)
    const [selectedSlide, setSelectedSlide] = useState(null)

    const onSetAnchor = (anchor) => {
        setAnchor(anchor)
    }

    const onSetSelectedSlide = (selectedSlide) => {
        setSelectedSlide(selectedSlide)
    }

    const onChangeLocale = (locale) => {
        changeLocale(locale)
        forceUpdate(!reloadState)
    }

    const locale = strings.getLanguage()

    const onUpdateRouter = (location) => {
        window.scrollTo(0, 0)
    }

    return (
        <AppContext.Provider
            value={{
                onChangeLocale,
                locale,
                onSetAnchor,
                anchor,
                selectedSlide,
                onSetSelectedSlide
            }}
        >
            <Router onUpdate={onUpdateRouter} history={browserHistory}>
                <Route path="feedback/ru/negative" component={Feedback} />
                <Route path="feedback/es/negative" component={Feedback} />
                <Route path="feedback/ru/positive" component={Feedback} />
                <Route path="feedback/es/positive" component={Feedback} />
                <Route path="/" component={AppLayer}>
                    <IndexRoute component={Content} />
                    <Route path="education" component={InformationManagement} />
                    {/* <Route path="prices" component={Prices} /> */}
                </Route>
            </Router>
        </AppContext.Provider>
    )
}

export default App
