import themeConfig from 'theme/themeConfig'

let generatedStyles = {}

;(() => {
    for (let key in themeConfig.colors) {
        if (themeConfig.colors.hasOwnProperty(key)) {
            generatedStyles[key] = {
                color: themeConfig.colors[key]
            }
        }
    }

    for (let key in themeConfig.fonts) {
        if (themeConfig.fonts.hasOwnProperty(key)) {
            generatedStyles[key] = {
                fontFamily: themeConfig.fonts[key]
            }
        }
    }

    for (let i = 10; i < 100; i += 2) {
        generatedStyles[`s${i}`] = {
            fontSize: i
        }
    }

    return generatedStyles
})()

const text = {
    ...generatedStyles,
    underline: {
        textDecoration: 'underline'
    },
    low: {
        textTransform: 'lowercase'
    },
    up: {
        textTransform: 'uppercase'
    },
    noUnderline: {
        textDecoration: 'none'
    },
    center: {
        textAlign: 'center'
    },
    bold: {
        fontWeight: 'bold'
    }
}

export default text
