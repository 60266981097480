import React from 'react'
import PropTypes from 'prop-types'

import { css } from 'aphrodite'
import styles from './styles'

const Button = ({ href, onClick = () => {}, title, className, children, ...props }) => (
    <a href={href} onClick={onClick} className={css(styles.button) + ' ' + className} {...props}>
        {children} {title}
    </a>
)

Button.propTypes = {
    href: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string
}

export default Button
