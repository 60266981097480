import React from 'react'

export default {
    facebook: {
        viewBox: '0 0 30 30',
        image: (
            <path d="M20.2134 15H16.8336V28H11.1917V15H9V10.6178H11.1917V7.82603C11.1917 4.17137 12.651 2 16.8011 2H21V6.36529H18.5588C16.9476 6.36529 16.8391 6.98568 16.8391 8.15878L16.8336 10.6178H20.6745L20.2134 15Z" />
        )
    },
    phone: {
        viewBox: '0 0 30 30',
        image: (
            <path d="M22.2364 18.2748L19.6912 17.9842C19.08 17.914 18.4788 18.1245 18.0479 18.5553L16.2042 20.3991C13.3685 18.9561 11.0438 16.6415 9.60092 13.7957L11.4547 11.942C11.8855 11.5111 12.0959 10.9099 12.0258 10.2987L11.7352 7.77358C11.615 6.76154 10.7633 6 9.7412 6H8.00771C6.87544 6 5.93354 6.9419 6.00368 8.07419C6.53475 16.6315 13.3785 23.4652 21.9257 23.9963C23.058 24.0665 23.9999 23.1246 23.9999 21.9923V20.2588C24.0099 19.2467 23.2484 18.395 22.2364 18.2748Z" />
        )
    },
    mail: {
        viewBox: '0 0 30 30',
        image: (
            <path d="M23 7H7C5.9 7 5 7.9 5 9V21C5 22.1 5.9 23 7 23H23C24.1 23 25 22.1 25 21V9C25 7.9 24.1 7 23 7ZM22.6 11.25L16.06 15.34C15.41 15.75 14.59 15.75 13.94 15.34L7.4 11.25C7.15 11.09 7 10.82 7 10.53C7 9.86 7.73 9.46 8.3 9.81L15 14L21.7 9.81C22.27 9.46 23 9.86 23 10.53C23 10.82 22.85 11.09 22.6 11.25Z" />
        )
    },
    linkedin: {
        viewBox: '0 0 148 148',
        image: (
            <g>
                <path d="M2.42999 49H33.05V147.48H2.42999V49ZM17.7399 2.81831e-06C21.251 -0.00197523 24.6838 1.03732 27.6041 2.98651C30.5244 4.93571 32.8009 7.70723 34.1459 10.9504C35.4909 14.1937 35.8439 17.7629 35.1601 21.2067C34.4763 24.6505 32.7866 27.8142 30.3046 30.2976C27.8226 32.781 24.6599 34.4725 21.2165 35.1582C17.7731 35.8439 14.2036 35.4929 10.9597 34.1498C7.71568 32.8066 4.94296 30.5316 2.99213 27.6124C1.04129 24.6932 5.57198e-07 21.261 0 17.75C0.00264626 13.045 1.87221 8.53324 5.19824 5.20533C8.52428 1.87742 13.0349 0.00530055 17.7399 2.81831e-06Z" />
                <path d="M52.25 49.0001H81.6101V62.4801H82.03C86.11 54.7401 96.1 46.5801 111.03 46.5801C142.03 46.5801 147.73 66.9701 147.73 93.5001V147.5H117.15V99.6001C117.15 88.1701 116.93 73.4801 101.24 73.4801C85.32 73.4801 82.89 85.9201 82.89 98.7701V147.48H52.29L52.25 49.0001Z" />
            </g>
        )
    },
    burger: {
        viewBox: '0 0 20 20',
        image: (
            <path d="M2 16H18C18.55 16 19 15.55 19 15C19 14.45 18.55 14 18 14H2C1.45 14 1 14.45 1 15C1 15.55 1.45 16 2 16ZM2 11H18C18.55 11 19 10.55 19 10C19 9.45 18.55 9 18 9H2C1.45 9 1 9.45 1 10C1 10.55 1.45 11 2 11ZM1 5C1 5.55 1.45 6 2 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4H2C1.45 4 1 4.45 1 5Z" />
        )
    },
    close: {
        viewBox: '0 0 20 20',
        image: (
            <g>
                <path d="M3.7072 5.12132L15.0209 16.435C15.4098 16.8239 16.0462 16.8239 16.4351 16.435C16.824 16.0461 16.824 15.4097 16.4351 15.0208L5.12141 3.70711C4.7325 3.3182 4.09611 3.3182 3.7072 3.70711C3.31829 4.09602 3.31829 4.73241 3.7072 5.12132Z" />
                <path d="M5.12132 16.4346L16.435 5.12094C16.8239 4.73203 16.8239 4.09563 16.435 3.70672C16.0461 3.31782 15.4097 3.31782 15.0208 3.70672L3.70711 15.0204C3.3182 15.4093 3.3182 16.0457 3.70711 16.4346C4.09602 16.8236 4.73241 16.8236 5.12132 16.4346Z" />
            </g>
        )
    },
    li: {
        viewBox: '0 0 10 10',
        image: (
            <path d="M9.54603 8.84078C9.06536 9.47081 8.13405 9.74083 6.87229 9.89084C5.43075 10.0824 3.9668 10.0215 2.54622 9.71083C1.1042 9.3208 0.172895 8.51076 0.0226841 7.22068C-0.127526 5.93061 0.473316 4.28051 1.675 2.72042C2.87668 1.16033 4.37879 0.170273 5.64056 0.0202647C6.90233 -0.129744 7.9538 0.560296 8.67481 1.85037C9.33632 3.14327 9.75385 4.54674 9.90654 5.99061C10.0868 7.28069 10.0267 8.24074 9.54603 8.84078Z" />
        )
    },
    analytic: {
        viewBox: '0 0 40 40',
        image: (
            <path d="M35.5997 10.4673L35.4997 10.3673C34.8497 9.71732 33.7831 9.75065 33.1831 10.434L29.5497 14.5173C26.1331 10.6673 21.3831 8.33398 16.0164 8.33398C11.8497 8.33398 7.96641 9.78398 4.76641 12.1673C3.98307 12.7507 3.89974 13.9007 4.58307 14.584L4.68307 14.684C5.23307 15.234 6.11641 15.334 6.73307 14.8673C9.38307 12.8673 12.5664 11.6673 16.0164 11.6673C20.5831 11.6673 24.4997 13.7673 27.2997 17.0673L22.4997 22.4673L17.0164 16.984C16.3664 16.334 15.3164 16.334 14.6664 16.984L4.46641 27.2007C3.84974 27.8173 3.84974 28.834 4.46641 29.4507L4.71641 29.7007C5.33307 30.3173 6.34974 30.3173 6.96641 29.7007L15.8331 20.8173L21.2497 26.234C21.9331 26.9173 23.0331 26.884 23.6664 26.1673L29.2497 19.9007C30.2831 21.7673 31.0497 23.884 31.4497 26.1173C31.5831 26.9007 32.2331 27.484 33.0331 27.484H33.1831C34.1831 27.484 34.9331 26.5673 34.7497 25.584C34.2164 22.5007 33.1164 19.684 31.5997 17.2507L35.6664 12.6673C36.2331 12.034 36.1997 11.0673 35.5997 10.4673V10.4673Z" />
        )
    },
    control: {
        viewBox: '0 0 40 40',
        image: (
            <path d="M34.9993 10H4.99935C3.16602 10 1.66602 11.5 1.66602 13.3333V26.6667C1.66602 28.5 3.16602 30 4.99935 30H34.9993C36.8327 30 38.3327 28.5 38.3327 26.6667V13.3333C38.3327 11.5 36.8327 10 34.9993 10ZM16.666 21.6667H13.3327V25C13.3327 25.9167 12.5827 26.6667 11.666 26.6667C10.7493 26.6667 9.99935 25.9167 9.99935 25V21.6667H6.66602C5.74935 21.6667 4.99935 20.9167 4.99935 20C4.99935 19.0833 5.74935 18.3333 6.66602 18.3333H9.99935V15C9.99935 14.0833 10.7493 13.3333 11.666 13.3333C12.5827 13.3333 13.3327 14.0833 13.3327 15V18.3333H16.666C17.5827 18.3333 18.3327 19.0833 18.3327 20C18.3327 20.9167 17.5827 21.6667 16.666 21.6667ZM25.8327 25C24.4493 25 23.3327 23.8833 23.3327 22.5C23.3327 21.1167 24.4493 20 25.8327 20C27.216 20 28.3327 21.1167 28.3327 22.5C28.3327 23.8833 27.216 25 25.8327 25ZM32.4993 20C31.116 20 29.9993 18.8833 29.9993 17.5C29.9993 16.1167 31.116 15 32.4993 15C33.8827 15 34.9993 16.1167 34.9993 17.5C34.9993 18.8833 33.8827 20 32.4993 20Z" />
        )
    },
    create: {
        viewBox: '0 0 40 40',
        image: (
            <path d="M30.0007 21.6673H21.6673V30.0007C21.6673 30.9173 20.9173 31.6673 20.0007 31.6673C19.084 31.6673 18.334 30.9173 18.334 30.0007V21.6673H10.0007C9.08398 21.6673 8.33398 20.9173 8.33398 20.0007C8.33398 19.084 9.08398 18.334 10.0007 18.334H18.334V10.0007C18.334 9.08398 19.084 8.33398 20.0007 8.33398C20.9173 8.33398 21.6673 9.08398 21.6673 10.0007V18.334H30.0007C30.9173 18.334 31.6673 19.084 31.6673 20.0007C31.6673 20.9173 30.9173 21.6673 30.0007 21.6673Z" />
        )
    },
    play: {
        viewBox: '0 0 40 40',
        image: (
            <path d="M20.0007 3.33398C10.8007 3.33398 3.33398 10.8007 3.33398 20.0007C3.33398 29.2007 10.8007 36.6673 20.0007 36.6673C29.2007 36.6673 36.6673 29.2007 36.6673 20.0007C36.6673 10.8007 29.2007 3.33398 20.0007 3.33398ZM16.6673 25.834V14.1673C16.6673 13.484 17.4507 13.084 18.0007 13.5007L25.784 19.334C26.234 19.6673 26.234 20.334 25.784 20.6673L18.0007 26.5007C17.4507 26.9173 16.6673 26.5173 16.6673 25.834Z" />
        )
    },
    playCircle: {
        viewBox: '0 0 66 66',
        image: (
            <path d="M33 5.5C17.82 5.5 5.5 17.82 5.5 33C5.5 48.18 17.82 60.5 33 60.5C48.18 60.5 60.5 48.18 60.5 33C60.5 17.82 48.18 5.5 33 5.5ZM27.5 42.625V23.375C27.5 22.2475 28.7925 21.5875 29.7 22.275L42.5425 31.9C43.285 32.45 43.285 33.55 42.5425 34.1L29.7 43.725C28.7925 44.4125 27.5 43.7525 27.5 42.625Z" />
        )
    },
    youtube: {
        viewBox: '0 0 512 512',
        image: (
            <g>
                <path
                    d="M490.24,113.92c-13.888-24.704-28.96-29.248-59.648-30.976C399.936,80.864,322.848,80,256.064,80
			c-66.912,0-144.032,0.864-174.656,2.912c-30.624,1.76-45.728,6.272-59.744,31.008C7.36,138.592,0,181.088,0,255.904
			C0,255.968,0,256,0,256c0,0.064,0,0.096,0,0.096v0.064c0,74.496,7.36,117.312,21.664,141.728
			c14.016,24.704,29.088,29.184,59.712,31.264C112.032,430.944,189.152,432,256.064,432c66.784,0,143.872-1.056,174.56-2.816
			c30.688-2.08,45.76-6.56,59.648-31.264C504.704,373.504,512,330.688,512,256.192c0,0,0-0.096,0-0.16c0,0,0-0.064,0-0.096
			C512,181.088,504.704,138.592,490.24,113.92z M192,352V160l160,96L192,352z"
                />
            </g>
        )
    },
    mailSupport: {
        viewBox: '-3 -3 30 30',
        image: (
            <g>
                <path d="M12.08 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4H20C21.1 4 22 4.9 22 6V12.68C21.09 12.25 20.08 12 19 12C15.13 12 12 15.13 12 19C12 19.34 12.03 19.67 12.08 20ZM11.47 12.67C11.79 12.87 12.21 12.87 12.53 12.67L19.6 8.25C19.85 8.09 20 7.82 20 7.53C20 6.86 19.27 6.46 18.7 6.81L12 11L5.3 6.81C4.73 6.46 4 6.86 4 7.53C4 7.82 4.15 8.09 4.4 8.25L11.47 12.67Z" />
                <path d="M20.3149 22.5C20.6049 22.38 20.8649 22.21 21.1149 22.02L21.0949 22.05L22.1049 22.44C22.3349 22.53 22.5949 22.44 22.7149 22.22L23.5549 20.76C23.6749 20.55 23.6249 20.27 23.4349 20.12L22.5849 19.44L22.5649 19.47C22.5849 19.31 22.6149 19.15 22.6149 18.99C22.6149 18.83 22.5849 18.67 22.5649 18.51L22.5849 18.54L23.4349 17.86C23.6249 17.71 23.6749 17.43 23.5549 17.22L22.7149 15.76C22.5949 15.55 22.3349 15.45 22.1049 15.54L21.0949 15.93L21.1149 15.96C20.8649 15.79 20.6049 15.62 20.3149 15.5L20.1449 14.42C20.1149 14.18 19.9049 14 19.6549 14H17.9749C17.7249 14 17.5149 14.18 17.4849 14.42L17.3149 15.5C17.0249 15.62 16.7649 15.79 16.5149 15.98L16.5349 15.95L15.5149 15.56C15.2849 15.47 15.0249 15.56 14.9049 15.78L14.0649 17.24C13.9449 17.45 13.9949 17.73 14.1849 17.88L15.0349 18.56L15.0549 18.53C15.0349 18.68 15.0049 18.84 15.0049 19C15.0049 19.16 15.0349 19.32 15.0549 19.48L15.0349 19.45L14.1849 20.13C13.9949 20.28 13.9449 20.56 14.0649 20.77L14.9049 22.23C15.0249 22.44 15.2849 22.54 15.5149 22.45L16.5249 22.06L16.5149 22.02C16.7649 22.21 17.0249 22.38 17.3149 22.5L17.4849 23.57C17.5149 23.82 17.7249 24 17.9749 24H19.6549C19.9049 24 20.1149 23.82 20.1449 23.58L20.3149 22.5ZM16.8149 19C16.8149 17.9 17.7149 17 18.8149 17C19.9149 17 20.8149 17.9 20.8149 19C20.8149 20.1 19.9149 21 18.8149 21C17.7149 21 16.8149 20.1 16.8149 19Z" />
            </g>
        )
    }
}
