import React from 'react'
import PropTypes from 'prop-types'

import translate from 'core/translate'

import { css } from 'aphrodite'
import styles from './styles'

const Title = ({ textKey, className, dividerSide }) => {
    const content = [<h2 key="title">{translate(textKey)}</h2>]
    const divider = <div key="divider" className={css(styles.divider, styles[`divider_${dividerSide}`])} />

    switch (dividerSide) {
        case 'right':
            content.push(divider)
            break
        default:
            content.unshift(divider)
    }

    return <div className={css(styles.title, styles[`title_${dividerSide}`], className)}>{content}</div>
}

Title.propTypes = {
    textKey: PropTypes.string.isRequired,
    className: PropTypes.object,
    dividerSide: PropTypes.string
}

export default Title
