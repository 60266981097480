import { StyleSheet } from 'aphrodite'
import gridStyles from './grid'
import itemStyles from './item'
import textStyles from './text'
import themeConfig from './themeConfig'

export const mixins = {
    grid: gridStyles,
    item: itemStyles,
    text: textStyles
}

export const grid = StyleSheet.create(gridStyles)
export const item = StyleSheet.create(itemStyles)
export const text = StyleSheet.create(textStyles)
export default themeConfig

export const mobileBreakpoint = `@media (max-width: ${themeConfig.mediaBreakpoint}px)`
export const mobileBreakpointDefault = `@media (max-width: 570px)`
export const breakpoint1550 = `@media (max-width: 1550px)`
export const desktopBreakpoint = `@media (min-width: 1800px)`
export const isMobile = () => window.matchMedia(`(max-width: ${themeConfig.mediaBreakpoint}px)`).matches
export const isDeviceLessThen480px = () => window.matchMedia(`(max-width: ${themeConfig.mediaBreakpointXS}px)`).matches
