import { StyleSheet } from 'aphrodite'
import { mixins, mobileBreakpoint } from 'theme'

const styles = StyleSheet.create({
    title: {
        ...mixins.grid.row,
        ...mixins.grid.normalCenter,
        ...mixins.grid.noWrap,
        ...mixins.text.montserrat,
        ...mixins.text.s48,
        ...mixins.text.bold,
        lineHeight: '60px',
        letterSpacing: '0.1em',
        [mobileBreakpoint]: {
            ...mixins.text.s24,
            lineHeight: '29px'
        }
    },
    title_right: {
        ...mixins.grid.flex,
        ...mixins.grid.justifyEnd,
        [mobileBreakpoint]: {
            ...mixins.grid.startJustify
        }
    },
    divider: {
        ...mixins.item.white,
        width: '15%',
        height: 4,
        marginRight: 30,
        [mobileBreakpoint]: {
            ...mixins.item.dNone
        }
    },
    divider_right: {
        marginRight: 0,
        marginLeft: 30
    }
})

export default styles
