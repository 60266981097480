import React from 'react'
import TextAndImage from '../../components/TextAndImage/index'
import translate from 'core/translate'
import { css } from 'aphrodite'
import styles from './styles'

const InformationManagementStart = () => (
    <div className={css(styles.whatWrapper)}>
        <span className={css(styles.backgroundGroup)}>
            <TextAndImage
                firstTextImg={true}
                textWidth={'30%'}
                imageWidth={'80%'}
                blockRightImg={true}
                text={<h1 className={css(styles.h1)}>{translate('informaM1')}</h1>}
                image={'assets/images/InformationManagement/InformationManagementStartImg.svg'}
            />
            <TextAndImage
                textWidth={'100%'}
                title={<p>{translate('informaM2')}</p>}
                text={
                    <>
                        <p>{translate('informaM3')}</p>
                        <p className={css(styles.boldP)}>{translate('informaM4')}</p>
                        <p>{translate('informaM5')}</p>
                    </>
                }
            />
            <TextAndImage
                textWidth={'60%'}
                imageWidth={'80%'}
                blockRightImg={true}
                noImgInMobile={true}
                image={'assets/images/InformationManagement/SalyVertical.svg'}
                title={<p>{translate('informaM6')}</p>}
                text={
                    <>
                        <p className={css(styles.boldP)}>{translate('informaM7')}</p>
                        <ul className={css(styles.ul)}>
                            <li className={css(styles.li)}>{translate('informaM8')}</li>
                            <li className={css(styles.li)}>{translate('informaM9')}</li>
                            <li className={css(styles.li)}>{translate('informaM10')}</li>
                            <li className={css(styles.li)}>{translate('informaM11')}</li>
                            <li className={css(styles.li)}>{translate('informaM12')}</li>
                            <li className={css(styles.li)}>{translate('informaM13')}</li>
                            <li className={css(styles.li)}>{translate('informaM14')}</li>
                            <li className={css(styles.li)}>{translate('informaM15')}</li>
                            <li className={css(styles.li)}>{translate('informaM16')}</li>
                            <li className={css(styles.li)}>{translate('informaM17')}</li>
                            <li className={css(styles.li)}>{translate('informaM18')}</li>
                            <li className={css(styles.li)}>{translate('informaM19')}</li>
                        </ul>
                    </>
                }
            />

            <TextAndImage
                textWidth={'100%'}
                text={
                    <>
                        <p className={css(styles.boldP)}>{translate('informaM20')}</p>
                        <ul className={css(styles.ul)}>
                            <li className={css(styles.li)}>{translate('informaM21')}</li>
                            <li className={css(styles.li)}>{translate('informaM22')}</li>
                            <li className={css(styles.li)}>{translate('informaM23')}</li>
                        </ul>
                        <p>{translate('informaM24')}</p>
                        <p className={css(styles.boldP)}>{translate('informaM25')}</p>
                        <ul className={css(styles.ul)}>
                            <li className={css(styles.li)}>{translate('informaM26')}</li>
                            <li className={css(styles.li)}>{translate('informaM27')}</li>
                            <li className={css(styles.li)}>{translate('informaM28')}</li>
                        </ul>
                    </>
                }
            />
            <div className={css(styles.emptyBlock)}></div>
        </span>

        <TextAndImage
            textWidth={'100%'}
            title={<p>{translate('informaM29')}</p>}
            text={
                <>
                    <p className={css(styles.boldP)}>{translate('informaM30')}</p>
                    <p>{translate('informaM31')}</p>
                    <p>{translate('informaM32')}</p>
                    <ul className={css(styles.ul)}>
                        <li className={css(styles.li)}>{translate('informaM33')}</li>
                        <li className={css(styles.li)}>{translate('informaM34')}</li>
                        <li className={css(styles.li)}>{translate('informaM35')}</li>
                        <li className={css(styles.li)}>{translate('informaM36')}</li>
                        <li className={css(styles.li)}>{translate('informaM37')}</li>
                        <li className={css(styles.li)}>{translate('informaM38')}</li>
                        <li className={css(styles.li)}>{translate('informaM39')}</li>
                    </ul>
                    <p></p>
                </>
            }
        />
        <TextAndImage
            textWidth={'100%'}
            title={<p>{translate('informaM40')}</p>}
            text={
                <>
                    <p>{translate('informaM41')}</p>
                </>
            }
        />
        <TextAndImage
            textWidth={'100%'}
            title={<p>{translate('informaM42')}</p>}
            text={
                <>
                    <p>{translate('informaM43')}</p>
                </>
            }
        />

        <TextAndImage
            textWidth={'100%'}
            title={<p>{translate('informaM44')}</p>}
            text={
                <>
                    <p>{translate('informaM45')}</p>
                </>
            }
        />
        <TextAndImage
            textWidth={'100%'}
            title={<p>{translate('informaM46')}</p>}
            text={
                <>
                    <p>{translate('informaM47')}</p>
                </>
            }
        />
        <TextAndImage blockImg={true} imageWidth={'50%'} image={'assets/images/InformationManagement/Saly.png'} />
    </div>
)

export default InformationManagementStart
