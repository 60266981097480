import { StyleSheet } from 'aphrodite'
import { mixins } from 'theme'
import './styles.css'

const styles = StyleSheet.create({
    checkboxLabel: {
        ...mixins.text.white,
        ...mixins.text.s12,
        lineHeight: '16px'
    }
})

export default styles
