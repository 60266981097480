import React from 'react'
import './animation.css'

// const statusColors = {
//     active: '#0085FF',
//     inactive: '#4C5A7D'
// }

const DisplaysSvg = ({ onClick, active }) => (
    <svg>
        <g
            onClick={() => {
                onClick('slide_0')
            }}
        >
            <path
                d="M1041 1117.63L1173.94 1050L1181 1053.33C1126.77 1171.08 1099.34 1100.7 1047.62 1213L1041 1208.95V1117.63Z"
                fill="#A5A5A5"
            />
            <path d="M1048 1122.23L1181 1055V1145.77L1048 1213V1122.23Z" fill="#C4C4C4" />
            <path
                d="M1041 1026.46L1173.94 958L1181 961.367C1126.77 1080.57 1099.34 1009.32 1047.62 1123L1041 1118.9V1026.46Z"
                fill="#A5A5A5"
            />
            <path d="M1048 1030.51L1181 962V1054.49L1048 1123V1030.51Z" fill="#C4C4C4" />
            <path d="M1048 937.66L1181 870V961.34L1048 1029V937.66Z" fill="#C4C4C4" />
            <path
                d="M1041 933.628L1173.94 866L1181 869.327C1126.77 987.08 1099.34 916.697 1047.62 1029L1041 1024.95V933.628Z"
                fill="#A5A5A5"
            />

            <path
                d="M1051 940L1178.5 875.5V959.5L1051 1024.5V940Z"
                //fill={active.slide_0 ? statusColors.active : statusColors.inactive}
                className={active.slide_0 ? 'active' : 'inactive'}
            />
            <path
                d="M1051 1032L1178.5 967.5V1051.5L1051 1116.5V1032Z"
                // fill={active.slide_0 ? statusColors.active : statusColors.inactive}
                className={active.slide_0 ? 'active' : 'inactive'}
            />
            <path
                d="M1051 1123.5L1178.5 1059V1143L1051 1208V1123.5Z"
                // fill={active.slide_0 ? statusColors.active : statusColors.inactive}
                className={active.slide_0 ? 'active' : 'inactive'}
            />
        </g>
        <g onClick={() => onClick('slide_1')}>
            <rect
                width="114.264"
                height="75.7623"
                transform="matrix(-0.991279 -0.131777 -0.131777 0.991279 389.252 465.449)"
                fill="url(#pattern0)"
            />
        </g>
        <g onClick={() => onClick('slide_2')}>
            <path
                d="M1361 496.583L1304 467V579.417L1361 609V496.583Z"
                //fill={active.slide_2 ? statusColors.active : statusColors.inactive}
                className={active.slide_2 ? 'active' : 'inactive'}
            />
        </g>
        <g onClick={() => onClick('slide_3')}>
            <path
                d="M1941 269.041L1899.28 247L1895 249.204C1911.96 287.402 1920.54 264.571 1936.72 301L1941 298.796V269.041Z"
                fill="#C9C9C9"
            />
            <path d="M1937 271.702L1895 250V279.298L1937 301V271.702Z" fill="#A5A5A5" />
            <path
                d="M1935 273L1896.5 253V278L1935 298V273Z"
                // fill={active.slide_3 ? statusColors.active : statusColors.inactive}
                className={active.slide_3 ? 'active' : 'inactive'}
            />
        </g>
        <g onClick={() => onClick('slide_4')}>
            <path
                d="M2163 237.49L2080.47 193L2072 197.449C2105.55 274.552 2122.53 228.466 2154.53 302L2163 297.551V237.49Z"
                fill="#C9C9C9"
            />
            <path d="M2156 242.255L2072 198V257.745L2156 302V242.255Z" fill="#A5A5A5" />
            <path
                d="M2151 244.5L2076.5 205.5V254.5L2151 294V244.5Z"
                // fill={active.slide_4 ? statusColors.active : statusColors.inactive}
                className={active.slide_4 ? 'active' : 'inactive'}
            />
        </g>
        <g onClick={() => onClick('slide_5')}>
            <path d="M2035 561L2107 523.5V670L2035 706.5V561Z" />
            <path d="M2289 434.5L2361 397V543.5L2289 580V434.5Z" />

            <path
                d="M2022 553.224L2102.72 512L2111 516.122C2078.18 587.566 2061.58 645.863 2030.28 714L2022 709.878V553.224Z"
                fill="#A5A5A5"
            />
            <path
                d="M2031 557.25L2111 516V672.75L2031 714V557.25Z"
                // fill={active.slide_5 ? statusColors.active : statusColors.inactive}
                className={active.slide_5 ? 'active' : 'inactive'}
            />

            <path
                d="M2277 424.837L2356.81 383L2365 387.184C2332.55 459.689 2316.13 518.852 2285.19 588L2277 583.816V424.837Z"
                fill="#A5A5A5"
            />
            <path
                d="M2286 430.458L2365 389V546.542L2286 588V430.458Z"
                // fill={active.slide_5 ? statusColors.active : statusColors.inactive}
                className={active.slide_5 ? 'active' : 'inactive'}
            />
        </g>
        <defs>
            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlinkHref="#image0" transform="translate(0 -0.00279364) scale(0.000244141 0.000368212)" />
            </pattern>
        </defs>
    </svg>
)

export default DisplaysSvg
