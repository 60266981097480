import React from 'react'
import Title from 'components/Title'

import { css } from 'aphrodite'
import styles from './styles.js'

const Trust = () => {
    return (
        <div className={css(styles.trustWrapper)}>
            <Title textKey="theyTrustUs" />
            <div className={css(styles.trustContent)}>
                <div className={css(styles.trustContentLayer)} />
                <div className={css(styles.trustLogosWrapper)}>
                    <div className={css(styles.trustLogosContent)}>
                        <img
                            src="assets/images/Trust/desktop.png"
                            alt=""
                            className={css(styles.trustLogosImage, styles.hiddenOnMobile)}
                        />
                        <img
                            src="assets/images/Trust/mobile.png"
                            alt=""
                            className={css(styles.trustLogosImage, styles.showOnMobile)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trust
