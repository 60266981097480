import { StyleSheet } from 'aphrodite'
import { mixins, mobileBreakpoint } from 'theme'

const styles = StyleSheet.create({
    footerWrapper: {
        position: 'relative',
        ...mixins.grid.rowCenter,
        ...mixins.item.footerBlack,
        padding: '5%',
        [mobileBreakpoint]: {
            padding: 20
        }
    },
    footerContent: {
        ...mixins.grid.rowCenter,
        ...mixins.grid.w70,
        [mobileBreakpoint]: {
            ...mixins.grid.w100,
            ...mixins.grid.col
        }
    },
    footerContentImage: {
        ...mixins.grid.w35,
        marginBottom: 60,
        [mobileBreakpoint]: {
            ...mixins.grid.w50,
            marginBottom: 40
        }
    },
    footerContentImageBig: {
        [mobileBreakpoint]: {
            ...mixins.item.dNone
        }
    },
    footerContentImageSmall: {
        ...mixins.item.dNone,
        [mobileBreakpoint]: {
            ...mixins.grid.row
        }
    },
    footerContentMenusWrapper: {
        ...mixins.grid.row,
        ...mixins.grid.justify,
        ...mixins.grid.w100
    },
    footerContentImageWrapper: {
        ...mixins.grid.rowCenter,
        ...mixins.grid.w100,
        [mobileBreakpoint]: {
            ...mixins.grid.startJustify
        }
    },
    footerCopyrights: {
        ...mixins.text.s12
    },
    sk: {
        display: 'block',
        width: '100%',
        margin: '50px 0',
        textAlign: 'center',

        [mobileBreakpoint]: {
            bottom: 50
        }
    },
    sk_img: {
        width: 60,

        [mobileBreakpoint]: {
            width: 50
        }
    }
})

export default styles
