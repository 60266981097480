import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'

import { css } from 'aphrodite'
import styles from './styles.js'
import translate from 'core/translate'

export const socials = [
    {
        icon: 'facebook',
        href: 'https://www.facebook.com/smartplayer.ru/',
        target: '_blank',
        text: 'facebook.com/smartplayer.ru/',
        showIn: ['footer', 'header'],
        showTextInFooter: true,
        isSocial: true
    },
    {
        icon: 'mail',
        href: 'mailto:' + window.spconfig.salesEmail,
        target: '',
        text: window.spconfig.salesEmail,
        showIn: ['footer', 'header'],
        showTextInFooter: true,
        description: 'supportForNewProject'
    },
    {
        icon: 'mailSupport',
        href: 'mailto:' + window.spconfig.supportEmail,
        target: '',
        text: window.spconfig.supportEmail,
        showIn: ['footer', 'header'],
        showTextInFooter: true,
        description: 'supportForExistedProject'
    },
    {
        icon: 'youtube',
        href: 'https://www.youtube.com/channel/UCST3vY-Ox4H25ZTl8RxzGWw/videos',
        target: '_blank',
        text: 'YouTube',
        showIn: ['footer', 'header'],
        showTextInFooter: true,
        isSocial: true
    },
    {
        icon: 'phone',
        href: window.spconfig.phoneLink,
        text: window.spconfig.phone,
        showTextInFooter: true,
        alwaysText: true,
        showIn: ['footer', 'header'],
        target: '',
        type: 'phone',
        description: 'supportAboutProducts'
    },
    {
        icon: 'linkedin',
        iconSize: 16,
        href: 'https://www.linkedin.com/company/smartplayer',
        text: 'linkedin.com/company/smartplayer',
        showTextInFooter: true,
        alwaysText: true,
        showIn: ['footer'],
        target: '_blank',
        isSocial: true
    }
]

const Socials = ({ position = 'header', className }) => {
    const renderSocialIcons = (position) => {
        switch (position) {
            case 'header':
                return socials
                    .filter((social) => social.showIn.includes('header'))
                    .map((social) => (
                        <a
                            className={css(
                                styles.socialLink,
                                social.isSocial && window.spconfig.disableSocialLinks ? styles.hideLink : '',
                                styles[`${position}SocialLink`]
                            )}
                            href={social.href}
                            key={social.icon}
                            target={social.target}
                            rel="noopener noreferrer"
                        >
                            <Icon type={social.icon} size={24} />
                            {social.text && social.alwaysText && (
                                <div className={css(styles.socialLinkText)}>{social.text}</div>
                            )}
                        </a>
                    ))
            case 'footer':
                return socials
                    .filter((social) => social.showIn.includes('footer'))
                    .map((social) => (
                        <>
                            <a
                                className={css(
                                    styles.socialLink,
                                    social.isSocial && window.spconfig.disableSocialLinks ? styles.hideLink : '',
                                    styles[`${position}SocialLink`]
                                )}
                                href={social.href}
                                key={social.icon}
                                target={social.target}
                                rel="noopener noreferrer"
                            >
                                <span className={css(styles.socialLinkIcon)}>
                                    <Icon
                                        style={{ margin: '0 auto' }}
                                        type={social.icon}
                                        size={social.iconSize ? social.iconSize : 24}
                                    />
                                </span>
                                {social.text && social.showTextInFooter && (
                                    <div className={css(styles.socialLinkText)}>{social.text}</div>
                                )}
                            </a>
                            {social.description && (
                                <div className={css(styles.description)}>{translate(social.description)}</div>
                            )}
                        </>
                    ))
            case 'header_mob':
                const socialPhone = socials.find((social) => social.type === 'phone')
                return (
                    <div className={css(styles[`${position}SocialLinkText`])}>
                        <a className={css(styles[`${position}SocialLink`])} href={socialPhone.href} target="">
                            {socialPhone.text}
                        </a>
                    </div>
                )
            default:
                return null
        }
    }

    return (
        <div className={css(styles.wrapper, styles[`${position}Wrapper`], className)}>
            {renderSocialIcons(position)}
        </div>
    )
}

Socials.propTypes = {
    position: PropTypes.oneOf(['header', 'footer', 'header_mob']),
    className: PropTypes.object
}

export default Socials
