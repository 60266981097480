import React from 'react'
import PropTypes from 'prop-types'
import icons from './list.js'

import themeConfig from 'theme'

const getIconColor = (color = 'white') => {
    if (themeConfig.colors[color]) {
        return themeConfig.colors[color]
    }

    return color
}

const Icon = ({ color, size, style = {}, type, ...props }) => {
    const iconView = icons[type]
    const iconColor = getIconColor(color)

    if (type && !iconView) {
        console.error(`Icon with type: ${type} not found`)
    }

    if (!iconView) {
        return null
    }

    const styleProp = {
        verticalAlign: 'middle',
        display: 'block',
        color: iconColor,
        ...style
    }

    return (
        <svg
            children={iconView.image}
            fill="currentColor"
            preserveAspectRatio="xMidYMid meet"
            height={size}
            width={size}
            viewBox={iconView.viewBox}
            style={styleProp}
            {...props}
        />
    )
}

Icon.propTypes = {
    color: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.object
}

Icon.defaultProps = {
    size: 16,
    color: 'white',
    onClick: () => {}
}
export default Icon
