import { StyleSheet } from 'aphrodite'
import { mixins, mobileBreakpoint } from 'theme'
import 'react-toastify/dist/ReactToastify.css'
import themeConfig from '../../theme/themeConfig'

const lineStyles = {
    ...mixins.grid.fullWidth,
    ...mixins.item.rel,
    ...mixins.item.white,
    content: '""',
    display: 'inline-block',
    verticalAlign: 'middle',
    height: 4
}

const styles = StyleSheet.create({
    contactsWrapper: {
        padding: '5%',
        background: 'linear-gradient(119.55deg, #353890 8.04%, #0D0F34 96.38%)',
        [mobileBreakpoint]: {
            padding: 20
        }
    },
    contactsBlock: {
        background: '#000000',
        width: '32%',
        padding: '20px',
        boxSizing: 'border-box',
        borderRadius: '5px',
        '@media (max-width: 450px)': {
            width: '100%',
            marginBottom: '16px'
        }
    },
    contactsPhoneDesc: {
        fontSize: '14px',
        marginTop: '12px',
        '@media (max-width: 450px)': {
            fontSize: '11px',
            marginTop: '4px'
        }
    },
    contactsContent: {
        ...mixins.grid.w70,
        margin: '0 auto',
        [mobileBreakpoint]: {
            ...mixins.grid.w100
        }
    },
    contactsTitle: {
        ...mixins.item.hidden,
        ...mixins.text.center,
        ...mixins.text.bold,
        ...mixins.text.s48,
        ...mixins.text.montserrat,
        lineHeight: '59px',
        letterSpacing: '0.1em',
        ':after': {
            ...lineStyles,
            marginRight: '-100%',
            right: -30
        },
        ':before': {
            ...lineStyles,
            marginLeft: '-100%',
            left: -30
        },
        [mobileBreakpoint]: {
            ...mixins.text.s24,
            lineHeight: '29px',
            marginBottom: 35,
            ':after': {
                ...mixins.item.dNone
            },
            ':before': {
                ...mixins.item.dNone
            }
        }
    },
    contactsMapWrapper: {
        ...mixins.grid.w100,
        ...mixins.item.rel,
        ...mixins.item.hidden,
        height: 500
    },
    contactsMapInfoWrapper: {
        //...mixins.grid.col,
        ...mixins.grid.row,
        ...mixins.grid.justify,
        //...mixins.item.abs,
        ...mixins.text.white,
        boxSizing: 'border-box',
        //height: 'calc(100% - 30px)',
        //maxWidth: '560px',
        margin: '0 auto',
        zIndex: 1,
        //background: `rgba(0,0,0, 0.8)`,
        borderRadius: 5,
        //padding: 18,
        top: 15,
        left: 15,
        textAlign: 'center',
        [mobileBreakpoint]: {
            ...mixins.grid.w100,
            borderRadius: 0,
            height: '50%',
            top: 0,
            left: 0,
            padding: 10
        }
    },
    contactsMapInfoTitle: {
        ...mixins.text.bold,
        ...mixins.text.s24,
        //marginRight: 50,
        marginBottom: 28,
        lineHeight: '33px',
        [mobileBreakpoint]: {
            ...mixins.text.s14,
            lineHeight: '19px',
            marginBottom: 10,
            marginRight: 0
        }
    },
    contactsMapInfoAdditional: {
        ...mixins.grid.w100,
        ...mixins.text.blue,
        ...mixins.text.s22,
        ...mixins.text.noUnderline,
        lineHeight: '25px',
        display: 'block',
        [mobileBreakpoint]: {
            ...mixins.text.s14,
            lineHeight: '19px'
        }
    },
    contactsMapInfoDescription: {
        ...mixins.grid.w100,
        ...mixins.text.s18,
        ...mixins.text.white,
        ...mixins.text.noUnderline,
        lineHeight: '25px',
        display: 'block',
        [mobileBreakpoint]: {
            ...mixins.text.s14,
            lineHeight: '19px'
        }
    },
    contactsMapInfoTwoColumns: {
        ...mixins.grid.row,
        ...mixins.grid.noWrap,
        justifyContent: 'space-around',
        marginTop: '50px',
        '@media (max-width: 450px)': {
            marginTop: 0
        }
    },
    contactsMail: {
        //marginTop: '50px'
    },
    contactsMapInfoLeftColumn: {
        //marginRight: 30
    },
    contactsFormWrapper: {
        marginTop: 20,
        marginBottom: 40
    },
    contactsFormContentWrapper: {
        ...mixins.grid.row,
        ...mixins.grid.justify,
        marginTop: 20
    },
    contactsFormContentLeft: {
        ...mixins.grid.w35,
        [mobileBreakpoint]: {
            ...mixins.grid.w100,
            marginBottom: 35
        }
    },
    contactsFormContentRight: {
        ...mixins.grid.w60,
        [mobileBreakpoint]: {
            ...mixins.grid.w100
        }
    },
    contactsFormTitle: {
        ...mixins.text.up,
        ...mixins.text.s18,
        fontWeight: 600,
        lineHeight: '25px',
        letterSpacing: '0.1em',
        [mobileBreakpoint]: {
            ...mixins.text.s14,
            ...mixins.text.center,
            lineHeight: '19px'
        }
    },
    contactsFormInput: {
        ...mixins.grid.w100,
        ...mixins.text.s14,
        ...mixins.text.white,
        ...mixins.text.openSans,
        boxSizing: 'border-box',
        lineHeight: '19px',
        background: `rgba(0,0,0, 0.5)`,
        borderRadius: 5,
        outline: 'none',
        border: 'none',
        padding: 10,
        marginBottom: 18,
        '::placeholder': {
            ...mixins.text.grey
        },
        ':last-child': {
            marginBottom: 0
        }
    },
    contactsFormInputError: {
        background: `rgba(222,111,111, 0.6)`,
        '::placeholder': {
            ...mixins.text.white
        }
    },
    contactsFormTextArea: {
        resize: 'none',
        height: '100%'
    },
    contactsFormFooter: {
        ...mixins.grid.row,
        ...mixins.grid.justify,
        ...mixins.grid.normalCenter,
        marginTop: 15
    },
    contactsFormFooterButton: {
        padding: '12px 50px',
        [mobileBreakpoint]: {
            ...mixins.grid.w100,
            ...mixins.text.center,
            marginTop: 20
        }
    },
    contactsFormFooterButtonDisabled: {
        pointerEvents: 'none',
        cursor: 'not-allowed',
        opacity: 0.4
    }
})

export default styles
