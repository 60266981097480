export default {
    colors: {
        footerBlack: '#191919',
        black: '#262626',
        white: '#ffffff',
        grey: '#6A6A6A',
        blue: '#3CB1F2',
        blueLight: '#0098FF'
    },
    fonts: {
        openSans: 'Open Sans',
        montserrat: 'Montserrat'
    },
    mediaBreakpoint: 1035,
    mediaBreakpointXS: 470
}
