import React, { useState, useContext } from 'react'
import Carousel from 'components/Carousel'
import Title from 'components/Title'

import { AppContext } from 'components/App'
import { isDeviceLessThen480px } from 'theme'

import { css } from 'aphrodite'
import styles from './styles'

const images = {
    desktop: {
        ru: ['assets/images/WhySmartplayer/slide1.png', 'assets/images/WhySmartplayer/slide3.png'],
        en: ['assets/images/WhySmartplayer/slide1_en.png', 'assets/images/WhySmartplayer/slide3_en.png']
    },
    mobile: {
        ru: ['assets/images/WhySmartplayer/slide1_mob.png', 'assets/images/WhySmartplayer/slide3_mob.png'],
        en: ['assets/images/WhySmartplayer/slide1_en_mob.png', 'assets/images/WhySmartplayer/slide3_en_mob.png']
    }
}

const WhySmartplayer = () => {
    const [deviceType] = useState(isDeviceLessThen480px() ? 'mobile' : 'desktop')
    const appContext = useContext(AppContext)
    const locale = appContext.locale
    const getCarouselImages = () => {
        return images[deviceType][locale] ? images[deviceType][locale] : images[deviceType]['en']
    }

    return (
        <div className={css(styles.whySmartplayerWrapper)}>
            <Title textKey="whySmartplayer" dividerSide="right" />
            <div className={css(styles.whySmartplayerContainer)}>
                <div className={css(styles.whySmartplayerContent)}>
                    {getCarouselImages() && getCarouselImages().length > 0 ? (
                        <Carousel images={getCarouselImages()} />
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default WhySmartplayer
